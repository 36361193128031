import { Css } from "~generated/css";
import { FloorFragment } from "~generated/graphql";

export function FloorPlanCard(props: FloorFragment) {
  const { name, image, id } = props;

  return (
    <div key={id} css={Css.bgWhite.p3.br4.df.gap1.maxhPx(500).$}>
      {/* Left Column */}
      <div css={Css.df.fdc.gap1.$}>
        <p css={Css.ttu.smEm.$}>Floor</p>
        <div css={Css.p1.bgGray100.df.aic.jcc.$}>
          <p css={Css.xl2Em.$}>{name}</p>
        </div>
      </div>
      {/* Right Column */}
      <div css={Css.df.aic.jcc.fg1.$}>
        <img css={Css.h100.$} src={image ?? undefined} alt={name} />
      </div>
    </div>
  );
}
