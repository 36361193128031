import { Link } from "react-router-dom";
import { Icon } from "~components";
import { Css } from "~generated/css";

type NextCTAProps = {
  heading: string;
  subheading: string;
  to: string;
};

export function NextCTA(props: NextCTAProps) {
  const { to, heading, subheading } = props;

  return (
    <div
      css={Css.pxPx(8 * 10).mt5.pt2.mb6.pb2.sticky.bottom0.df.aic.jcsb.$}
      style={{
        background: "linear-gradient(180deg, rgba(247,245,245,0.9) 0%, rgba(247,245,245,1) 100%)",
      }}
    >
      <div>
        <p css={Css.xl3Em.mb1.$}>{heading}</p>
        <p css={Css.sm.$}>{subheading}</p>
      </div>
      {/* Right Button */}
      <Link to={to} css={Css.hPx(50).wPx(50).bgGray800.df.aic.jcc.$}>
        <Icon name="arrow-next" />
      </Link>
    </div>
  );
}
