import { Css } from "~generated/css";

type SwitcherProps<Item extends string> = {
  items?: Item[];
  selectedItem?: Item;
  label?: string;
  onChange?: (item: Item) => void;
};

/**
 * Renders a horizontal list of strings which can be selected.
 *
 * IMPROVEMENTS:
 * - Add framer motion to animate the selection.
 */
export function Switcher<Item extends string>(props: SwitcherProps<Item>) {
  const { items, selectedItem, label, onChange } = props;

  if (!items) return null;

  return (
    <div>
      {label && <label css={Css.xsEm.mb1.db.$}>{label}</label>}

      {/* Background */}
      <div css={Css.bgGray50.br4.bshModal.py1.px2.df.fdr.gap1.$}>
        {items.map((item) => {
          const selected = selectedItem === item;

          return (
            <button
              key={item}
              css={Css.fg1.br16.tc.pyPx(4).if(selected).bgColor("#353535").white.else.bgWhite.gray700.$}
              onClick={() => onChange?.(item)}
            >
              <p css={Css.smEm.$}>{item}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
}
