import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "~components";
import { isFullAccessUser, useConfiguration } from "~contexts";
import { Css } from "~generated/css";
import { currencyFormatter, dateFormatter } from "~utils";

/**
 * The Header component can be in two states:
 * 1. The configuration is not reserved -> "Reserved".
 * 2. The configuration is reserved -> "Not Reserved".
 *
 * Each state will renders a slightly different UI.
 */
export function Header() {
  const { code, createdAt, resetConfiguration, totalPriceInCents, canReserve } = useConfiguration();
  const navigate = useNavigate();
  const location = useLocation();

  function handleDesignAnotherHomeClick() {
    // Reset the configuration
    resetConfiguration();
    // Navigate to the first step
    navigate("/architecture");
  }

  // On the summary page, the button should not be shown
  const showReserveButton = location.pathname !== "/summary";

  return (
    <header css={Css.bgWhite.bshBasic.z1.$}>
      <div css={Css.maxwPx(1440).mx("auto").px8.py4.df.aic.jcsb.$}>
        {/* Left Logo */}
        <img src="/logo.svg" alt="Homebound" />
        {/* Right Details Container */}
        <div css={Css.df.fdr.aic.gap3.$}>
          {/* `Not Reserved` State */}
          {!code && (
            <>
              <p css={Css.xlEm.$}>{currencyFormatter(totalPriceInCents)}</p>
              {showReserveButton && (
                <Button
                  onClick={() => navigate("/summary")}
                  iconLeft="cart"
                  // Disable the button with an error message if the configuration
                  // cannot be reserved at the moment. This happens when the
                  // configuration is missing architecture and interior selections.
                  disabled={
                    !canReserve && "You cannot reserve until you have selected an architecture and an interior."
                  }
                  tooltipPlacement={{ right: 0, top: "calc(100% + 8px)" }}
                >
                  Reserve
                </Button>
              )}
            </>
          )}
          {/* `Reserved State` */}
          {code && createdAt && (
            <>
              <p css={Css.lg.$}>
                Reserved: <span css={Css.lgEm.$}>{dateFormatter(createdAt, "MMMM d, y")}</span>
              </p>
              <p css={Css.lg.$}>
                Code: <span css={Css.lgEm.$}>{code}</span>
              </p>
              {
                // render button for full access users only
                isFullAccessUser() && <Button onClick={handleDesignAnotherHomeClick}>Design Another Home</Button>
              }
            </>
          )}
        </div>
      </div>
    </header>
  );
}
