import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import {
  ElevationFragment,
  ElevationStyleFragment,
  OptionFragment,
  PlanFragment,
  SpecLevelFragment,
  SpecLevelStyleFragment,
} from "~generated/graphql";

/** Configuration Context **/
export type ConfigurationValues = {
  code?: string; // Signifies that a configuration is reserved. Used to show the reserved/confirmation step.
  createdAt?: string; // The date the configuration was reserved

  // Main Features (Plan, Elevation, ElevationStyle, SpecLevel, SpecLevelStyle)
  plan?: PlanFragment;
  elevation?: ElevationFragment;
  elevationStyle?: ElevationStyleFragment;
  specLevel?: SpecLevelFragment;
  specLevelStyle?: SpecLevelStyleFragment;
  mainFeaturesPriceInCents?: number; // Used to show the cost per sqft

  // Options
  options?: OptionFragment[];

  // Price of the configuration
  totalPriceInCents?: number;

  // Helper boolean to determine if the configuration can be reserved.
  canReserve: boolean;

  // LocalStorage value that determines access to app
  lsAppAccess?: string | null;
};

export type ConfigurationActions = {
  // These actions are used throughout the configuration step
  setPlan: (plan: PlanFragment) => void;
  setElevation: (elevation: ElevationFragment) => void;
  setElevationStyle: (elevationStyle: ElevationStyleFragment) => void;
  setSpecLevel: (specLevel: SpecLevelFragment) => void;
  setSpecLevelStyle: (specLevelStyle: SpecLevelStyleFragment) => void;
  toggleOption: (option: OptionFragment) => void;

  // This action is used on the `/summary/:code` page to push the reserved
  // configuration into context.
  setConfiguration: (configuration: Omit<ConfigurationValues, "priceInCents" | "canReserve">) => void;

  // This action removed the saved configuration from sessionStorage and resets
  // the configuration context.
  resetConfiguration: () => void;
};

export type ConfigurationContext = ConfigurationValues & ConfigurationActions;
// Intentionally naming the variable the same as the type
// eslint-disable-next-line @typescript-eslint/no-redeclare
const ConfigurationContext = createContext<ConfigurationContext | null>(null);

/** Configuration Provider **/
/**
 * Provider that exposed configuration values and actions for us in the application.
 */
type ConfigurationProviderProps = { children: ReactNode };
export function ConfigurationProvider(props: ConfigurationProviderProps) {
  const { children } = props;

  // Throw when there are more than one CartProvider
  const configurationContext = useContext(ConfigurationContext);
  if (configurationContext !== null)
    throw new Error("ConfigurationProvider should not be a child of another ConfigurationProvider");

  const [configuration, setConfiguration] = useState<ConfigurationContext>(() => {
    // Check if there is an access key provided in localStorage
    const lsAppAccess = getLocalStorageKey<string | null | undefined>("checkoutFullAccess");
    // Check if there is any saved date in sessionStorage
    const savedConfiguration = getStorageKey<ConfigurationValues>("configuration") ?? {};

    return {
      lsAppAccess,
      ...savedConfiguration,
      // Total Price
      totalPriceInCents: 0,
      // Reservation rule
      canReserve: false,

      // Main Features
      mainFeaturesPriceInCents: 0, // Defaults to $0 until a main feature is selected

      // Actions
      setPlan,
      setElevation,
      setElevationStyle,
      setSpecLevel,
      setSpecLevelStyle,
      toggleOption,
      setConfiguration: _setConfiguration,
      resetConfiguration,
    };
  });

  function setPlan(plan: PlanFragment) {
    setConfiguration((prevConfiguration) => {
      // When changing the plan, we want to select an equivalent elevation so
      // the user doesn't need to reselect the elevation.
      // Firstly, we get the current elevation.
      const currentElevation = prevConfiguration.elevation;
      // Then find the index of the elevation for the previous chosen plan.
      const currentElevationIndex = prevConfiguration.plan?.elevations.findIndex(
        (elevation) => elevation.id === currentElevation?.id,
      );
      // Finally, we find the elevation for the new chosen plan or default to the
      // first elevation if there are any errors.
      const newElevation = plan.elevations[currentElevationIndex ?? 0] ?? plan.elevations[0];

      // When changing the plan, we want to select an equivalent elevation style
      // so the user does not need to reselect the elevation style.
      // Firstly, we find the current elevation style id.
      const currentElevationStyle = prevConfiguration.elevationStyle;
      // Then find the index of the elevation style for the previous chosen elevation
      const currentElevationStyleIndex = currentElevation?.styles.findIndex(
        (elevationStyle) => elevationStyle.id === currentElevationStyle?.id,
      );
      // Finally, we find the elevation style for the new chosen elevation or default to the
      // first elevation style if there are any errors.
      const newElevationStyle = newElevation.styles[currentElevationStyleIndex ?? 0] ?? newElevation.styles[0];

      // Update the configuration
      return {
        ...prevConfiguration,
        plan,
        elevation: newElevation,
        elevationStyle: newElevationStyle,
        // Reset the spec level and spec level style
        specLevel: undefined,
        specLevelStyle: undefined,
        // Reset the options
        options: undefined,
      };
    });
  }

  function setElevation(elevation: ElevationFragment) {
    setConfiguration((prevConfiguration) => {
      // TODO: This is duplicate code from the setPlan function. Refactor this.
      // When changing the elevation, we want to select an equivalent elevation style
      // so the user does not need to reselect the elevation style.
      // Firstly, we find the current elevation style id.
      const currentElevationStyle = prevConfiguration.elevationStyle;
      // Then find the index of the elevation style for the previous chosen elevation
      const currentElevationStyleIndex = prevConfiguration.elevation?.styles.findIndex(
        (elevationStyle) => elevationStyle.id === currentElevationStyle?.id,
      );
      // Finally, we find the elevation style for the new chosen elevation or default to the
      // first elevation style if there are any errors.
      const newElevationStyle = elevation.styles[currentElevationStyleIndex ?? 0] ?? elevation.styles[0];

      // Update the configuration
      return {
        ...prevConfiguration,
        elevation,
        elevationStyle: newElevationStyle,
        // Reset the spec level and spec level style
        specLevel: undefined,
        specLevelStyle: undefined,
        // Reset the options
        options: undefined,
      };
    });
  }

  function setElevationStyle(elevationStyle: ElevationStyleFragment) {
    setConfiguration((prevConfiguration) => ({ ...prevConfiguration, elevationStyle }));
  }

  function setSpecLevel(specLevel: SpecLevelFragment) {
    setConfiguration((prevConfiguration) => {
      // When changing the spec level, we want to select an equivalent spec
      // level style so users do not need to reselect their chosen spec level
      // style.
      // Firstly, find the currently selected spec level
      const currentSpecLevel = prevConfiguration.specLevel;
      // Then find the index of the currently selected spec level style
      const currentSpecLevelStyleIndex = currentSpecLevel?.styles.findIndex(
        (specLevelStyle) => specLevelStyle.id === prevConfiguration.specLevelStyle?.id,
      );
      // Finally, find the spec level style for the new spec level or default
      // to the first spec level style if there are any errors.
      const newSpecLevelStyle = specLevel.styles[currentSpecLevelStyleIndex ?? 0] ?? specLevel.styles[0];

      // Update the configuration
      return {
        ...prevConfiguration,
        specLevel,
        specLevelStyle: newSpecLevelStyle,
        // Reset the options
        options: undefined,
      };
    });
  }

  function setSpecLevelStyle(specLevelStyle: SpecLevelStyleFragment) {
    setConfiguration((prevConfiguration) => ({ ...prevConfiguration, specLevelStyle }));
  }

  function toggleOption(option: OptionFragment) {
    setConfiguration((prevConfiguration) => {
      const { options: _options = [] } = prevConfiguration;
      let options = _options;

      // If the option is already selected, remove it
      if (_options.find(({ id }) => id === option.id)) {
        // Remove the option from the list of selected options
        options = options.filter(({ id }) => id !== option.id);
      }
      // If the option is not selected, add it
      else {
        // Add the option to the list of selected options
        options = [...options, option];
      }

      return { ...prevConfiguration, options };
    });
  }

  function _setConfiguration(configuration: Partial<ConfigurationValues>) {
    setConfiguration((prevConfiguration) => ({
      // Keep the actions but replace all the values
      ...prevConfiguration,
      ...configuration,
    }));
  }

  function resetConfiguration() {
    // use existing value for localStorage if exists otherwise reset will set value to undefined
    const lsAppAccess = getLocalStorageKey<string | null | undefined>("checkoutFullAccess");

    setStorageKey("configuration", {});
    // Keep the actions, but reset the values
    setConfiguration({
      // Clean this up so that it's easier to reset without needing to remember
      // to keep the actions.
      totalPriceInCents: 0,
      canReserve: false,

      // Main Features
      mainFeaturesPriceInCents: 0, // Defaults to $0 until a main feature is selected

      setPlan,
      setElevation,
      setElevationStyle,
      setSpecLevel,
      setSpecLevelStyle,
      toggleOption,
      setConfiguration: _setConfiguration,
      resetConfiguration,

      lsAppAccess,
    });
  }

  /**
   * Recalculate mainFeaturesPriceInCents and totalPriceInCents when any
   * configuration selections/option change.
   */
  useEffect(() => {
    setConfiguration((prevConfiguration) => {
      const mainFeaturesPriceInCents = calculateMainFeaturesPriceInCents(prevConfiguration);
      const totalPriceInCents = mainFeaturesPriceInCents + calculateOptionsPriceInCents(prevConfiguration);
      return { ...prevConfiguration, mainFeaturesPriceInCents, totalPriceInCents };
    });
  }, [
    configuration.plan,
    configuration.elevation,
    configuration.elevationStyle,
    configuration.specLevel,
    configuration.specLevelStyle,
    configuration.options,
  ]);

  // When any configuration changes, determine if the configuration can be reserved
  useEffect(() => {
    // We must do this check in the setConfiguration function since this is the
    // only way to get the latest state values. Otherwise the state values will
    // be stale.
    setConfiguration((prevConfiguration) => {
      const { plan, elevation, elevationStyle, specLevel, specLevelStyle } = prevConfiguration;

      // A reservation can only be made once the user has completed the Architecture
      // and Interior step.
      if (plan && elevation && elevationStyle && specLevel && specLevelStyle) {
        return { ...prevConfiguration, canReserve: true };
      }
      return prevConfiguration;
    });
  }, [
    configuration.plan,
    configuration.elevation,
    configuration.elevationStyle,
    configuration.specLevel,
    configuration.specLevelStyle,
    configuration.options,
  ]);

  // When the configuration changes, save it in sessionStorage
  useEffect(() => {
    setConfiguration((prevConfiguration) => {
      const { code, plan, elevation, elevationStyle, specLevel, specLevelStyle, options } = prevConfiguration;

      setStorageKey("configuration", {
        code,
        plan,
        elevation,
        elevationStyle,
        specLevel,
        specLevelStyle,
        options,
      });

      return prevConfiguration;
    });
  }, [
    configuration.code,
    configuration.plan,
    configuration.elevation,
    configuration.elevationStyle,
    configuration.specLevel,
    configuration.specLevelStyle,
    configuration.options,
  ]);

  return <ConfigurationContext.Provider value={configuration}>{children}</ConfigurationContext.Provider>;
}

/** Configuration Hook **/
export function useConfiguration() {
  const context = useContext(ConfigurationContext);

  // Throw when there is no ConfigurationProvider as a parent
  if (context === null) throw new Error("useConfiguration must be used within a ConfigurationProvider");

  return context;
}

/** Helpers **/
export function getStorageKey<T>(key: string): T | null {
  const value = sessionStorage.getItem(key);
  if (value) return JSON.parse(value);
  return null;
}

export function setStorageKey(key: string, value: Parameters<typeof JSON.stringify>[0]) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

/** Helpers - app access **/
export function getLocalStorageKey<T>(key: string): T | null {
  const value = localStorage.getItem(key);
  if (value) return JSON.parse(value);
  return null;
}

export function setLocalStorageKey(key: string, value: Parameters<typeof JSON.stringify>[0]) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function isFullAccessUser() {
  const lsAppAccess = getLocalStorageKey("checkoutFullAccess");
  return lsAppAccess && lsAppAccess === "hb-sales";
}

/** Helpers */
/** Given options, calculate the total price of options  */
function calculateOptionsPriceInCents({ options }: Pick<ConfigurationValues, "options">) {
  return options?.reduce((acc, option) => acc + option.priceInCents, 0) ?? 0;
}

type MainFeatures = Pick<ConfigurationValues, "plan" | "elevation" | "elevationStyle" | "specLevel" | "specLevelStyle">;
/** Given main features, calculate the total price of the main features */
function calculateMainFeaturesPriceInCents(mainFeatures: MainFeatures) {
  const { plan, elevation, elevationStyle, specLevel, specLevelStyle } = mainFeatures;
  return (
    maybePriceInCents(plan) +
    maybePriceInCents(elevation) +
    maybePriceInCents(elevationStyle) +
    maybePriceInCents(specLevel) +
    maybePriceInCents(specLevelStyle)
  );
}

function maybePriceInCents(entity?: { priceInCents: number }) {
  return entity?.priceInCents ?? 0;
}
