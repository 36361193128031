import { SyntheticEvent, useState } from "react";
import { phoneMask } from "~utils";
import { TextField } from "../TextField/TextField";

interface PhoneFieldProps {
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
}

export function PhoneField(props: PhoneFieldProps) {
  const { onChange, placeholder, value = "" } = props;
  const { onInput, onKeydown, mask, unMask } = phoneMask();
  const [internalInputValue, setInternalInputValue] = useState<string>(mask(value));

  function onChangeInternal(e: SyntheticEvent) {
    const { newValue } = onInput(e);
    // use masked value for internal input
    setInternalInputValue(newValue);
    // provide un-masked value to caller
    onChange?.(unMask(newValue));
  }

  return (
    <TextField
      onChange={onChangeInternal}
      onKeyDown={onKeydown}
      placeholder={placeholder}
      testId="PhoneField"
      type="tel"
      value={internalInputValue}
    />
  );
}
