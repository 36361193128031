import { motion } from "framer-motion";
import { Css, Margin, Xss } from "~generated/css";

export type TabXss = Xss<Margin>;
export type TabProps<Option> = {
  options: Option[];
  selected: Option;
  onChange?: (selected: Option) => void;
  xss?: TabXss;
};

export function Tab<Option extends string>(props: TabProps<Option>) {
  const { options, selected, onChange, xss } = props;

  return (
    // Wrapper
    <div css={{ ...Css.df.jcsb.gap1.bb.bw1.bGray400.$, ...xss }}>
      {options.map((option) => {
        const isActive = selected === option;
        return (
          // Text container with black bottom border
          <motion.div key={option} css={Css.bb.bw("4px").$} animate={isActive ? Css.bGray900.$ : Css.bWhite.$}>
            {/* Text */}
            <motion.p
              animate={isActive ? Css.gray900.$ : Css.gray700.$}
              transition={{ duration: 0.2 }}
              css={Css.noUnderline.sm.gray900.br24.if(!!onChange).cursorPointer.$}
              onClick={() => onChange?.(option)}
            >
              {option}
            </motion.p>
          </motion.div>
        );
      })}
    </div>
  );
}
