import { useState } from "react";
import { Map, MapMarker, RadioField } from "~components";
import { Css } from "~generated/css";
import { currencyFormatter } from "~utils";

interface HbLot {
  id: string;
  address: string;
  county: string;
  details: string;
  image: string;
  priceInCents: number;
  lat: number;
  lng: number;
}

const hbLots: HbLot[] = [
  {
    id: "1",
    address: "356 Shawnee Lane",
    county: "Boulder County",
    details: "3 architecture plan options",
    image: "/map-thumbnail-1.png",
    priceInCents: 1_100_000_00,
    lat: 39.95654,
    lng: -105.17692,
  },
  {
    id: "2",
    address: "123 Shawnee Lane",
    county: "Boulder County",
    details: "4 architecture plan options",
    image: "/map-thumbnail-2.png",
    priceInCents: 1_300_000_00,
    lat: 39.95591,
    lng: -105.17734,
  },
  {
    id: "3",
    address: "789 Shawnee Lane",
    county: "Boulder County",
    details: "3 architecture plan options",
    image: "/map-thumbnail-3.png",
    priceInCents: 1_100_000_00,
    lat: 39.95701,
    lng: -105.17734,
  },
];

export function Bool() {
  const [selectedLot, setSelectedLot] = useState<HbLot | undefined>();

  return (
    <>
      {/* Map */}
      <div css={Css.w100.hPx(576).$}>
        <Map
          markers={hbLots.map((lot) => {
            const { id, lat, lng } = lot;
            const isActive = selectedLot?.id === id;
            return {
              id,
              center: { lat, lng },
              isActive,
              marker: (
                <MapMarker testId={`bool-map-marker-${id}`} isActive={isActive} onClick={() => setSelectedLot(lot)} />
              ),
            };
          })}
        />
      </div>

      <div css={Css.pxPx(8 * 10).pt4.$}>
        <h2 css={Css.xl3Em.mb2.$}>Available Lots</h2>
        {/* Lot Selector */}
        <RadioField options={hbLots} value={selectedLot?.id} onChange={(lot) => setSelectedLot(lot)} testId="bool">
          {(option, context) => {
            const { id, address, county, details, priceInCents, lat, lng } = option;
            const { selected } = context;
            return (
              <>
                <div css={Css.wPx(300).hPx(180).br4.relative.overflowHidden.mb1.bshHover.$}>
                  {/* TODO: Animate this color change with Framer Motion */}
                  <div css={Css.hPx(10).absolute.z1.top0.left0.right0.if(selected).bgYellow500.$} />

                  {/* Map */}
                  <div css={Css.h100.w100.pointerNone.$}>
                    <Map
                      markers={[{ id, center: { lat, lng }, isActive: selected }]}
                      features={{
                        doubleClickZoom: false,
                        scrollWheelZoom: false,
                        touchZoom: false,
                        zoomControl: false,
                        tap: false,
                        dragging: false,
                      }}
                    />
                  </div>

                  <div
                    css={Css.absolute.bottom3.right3.px2.py1.br4.smEm.if(selected).bgYellow500.else.bgGray800.white.$}
                  >
                    {selected ? "Selected" : "Select"}
                  </div>
                </div>
                <div>
                  <div css={Css.df.aic.$}>
                    <span css={Css.dib.w1.h1.bgRed600.mrPx(4).$}></span>
                    <span css={Css.ttu.tinyEm.red600.$}>{county}</span>
                  </div>
                  <p css={Css.xlEm.$}>{address}</p>
                  <p css={Css.base.$}>{details}</p>
                  <p css={Css.base.$}>{`${currencyFormatter(priceInCents)}+ with base home`}</p>
                </div>
              </>
            );
          }}
        </RadioField>
      </div>
    </>
  );
}
