import { HTMLInputTypeAttribute, SyntheticEvent } from "react";
import { Css } from "~generated/css";

// Input types that are supported via a text input.
type TextInputType = Extract<HTMLInputTypeAttribute, "email" | "password" | "search" | "tel" | "text">;

// A TextField can either be disabled, readOnly, or neither.
type DisabledTextField = {
  disabled?: boolean;
};
type ReadOnlyTextField = {
  readOnly?: boolean;
};
export type TextFieldProps = {
  testId?: string;
  label?: string;
  onChange?: (e: SyntheticEvent, value: string) => void;
  onKeyDown?: (e: SyntheticEvent) => void;
  placeholder?: string;
  required?: boolean;
  /** @default "text" */
  type?: TextInputType;
  value?: string;
} & (DisabledTextField | ReadOnlyTextField);

export function TextField(props: TextFieldProps) {
  const { testId = "TextField", label, onChange, onKeyDown, placeholder, required, value = "", type = "text" } = props;
  const disabled = "disabled" in props ? props.disabled ?? false : false;
  const readOnly = "readOnly" in props ? props.readOnly ?? false : false;

  return (
    <label data-testid={testId}>
      {/* Label */}
      {label && (
        <span data-testid={`${testId}-label`} css={Css.tinyEm.mbPx(4).db.if(disabled).gray500.$}>
          {label}
        </span>
      )}

      {/* Input */}
      <input
        data-testid={`${testId}-input`}
        css={
          Css.bgGray50.br4.px2.py1.bshBasic.smEm.w100.outline0.if(disabled).gray500.cursorNotAllowed.if(readOnly)
            .bgTransparent.bsh0.pl0.$
        }
        disabled={disabled}
        value={value}
        onChange={(e) => onChange?.(e, e.target.value)}
        onKeyDown={(e) => onKeyDown?.(e)}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type}
      />
    </label>
  );
}
