import { motion } from "framer-motion";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Detail, DualPaneLayout, FloorPlanCard, Icon, NextCTA, Tooltip } from "~components";
import { ConfigurationActions, ConfigurationValues, useConfiguration } from "~contexts";
import { Css } from "~generated/css";
import { OptionFragment, useOptionsQuery } from "~generated/graphql";
import { currencyFormatterWithSign, groupBy, HasId, ID, isValidOptionDetail } from "~utils";

type OptionsConfigurationValues = Pick<ConfigurationValues, "options">;
type OptionsConfigurationActions = Pick<ConfigurationActions, "toggleOption">;

export function OptionsStep() {
  const navigate = useNavigate();

  const { elevation, specLevel, options: selectedOptions, toggleOption } = useConfiguration();

  const { data } = useOptionsQuery({ variables: { specLevelId: specLevel?.id ?? "" }, skip: !specLevel });
  const options = useMemo(() => data?.options as OptionFragment[], [data]);

  // This state stores last option row that was clicked. This is used to tell the
  // right pane which option information to display.
  const [activeOption, setActiveOption] = useState<OptionFragment | undefined>();
  // When options are returned, set the first option as the active option.
  // This is done here vs in the useState hook because we need to wait for the
  // options to be loaded before we can set the active option.
  useEffect(() => {
    if (options) setActiveOption(options[0]);
  }, [options]);

  // Redirect to `/interior` or `/architecture` when missing `elevation` or `specLevel`
  useEffect(() => {
    // `elevation` can only be chosen on `/architecture` step
    if (!elevation) navigate("/architecture");
    // `specLevel` can only be chosen on `/interior` step
    else if (!specLevel) navigate("/interior");
  }, [elevation, specLevel, navigate]);

  // Don't render the page if we are missing `specLevel` or `options`.
  // `specLevel` could be missing due to skipping `/interior` step
  // `options` could be missing due to waiting on the network response.
  // TODO: Should show an loading animation
  if (!specLevel || !options) return null;
  return (
    <DualPaneLayout>
      {{
        left: (
          <LeftPane
            options={options}
            activeOptionContext={{ activeOption, setActiveOption }}
            configurationValues={{ options: selectedOptions }}
            configurationActions={{ toggleOption }}
          />
        ),
        right: (
          <RightPane
            option={activeOption}
            configurationValues={{ options: selectedOptions }}
            configurationActions={{ toggleOption }}
          />
        ),
      }}
    </DualPaneLayout>
  );
}

type LeftPaneProps = {
  // These are all the options to show
  options?: OptionFragment[];
  // TODO: This duplicate TS type is annoying when needing to change the useState type...
  activeOptionContext: { activeOption?: OptionFragment; setActiveOption: (option: OptionFragment) => void };
  configurationValues: OptionsConfigurationValues;
  configurationActions: OptionsConfigurationActions;
};
function LeftPane(props: LeftPaneProps) {
  const { options: globalOptions, activeOptionContext, configurationValues, configurationActions } = props;
  const { activeOption, setActiveOption } = activeOptionContext;
  const { options } = configurationValues;
  const { toggleOption } = configurationActions;

  // Group options per group
  const optionsPerGroup = groupBy(globalOptions ?? [], (option) => option.group);

  // Find all unique conflict ids from selected options
  const disabledOptionIds = [
    ...new Set(
      (options ?? [])
        .flatMap(
          (o) =>
            // Note that `optionToOptions` is the same as `conflicts`
            o.optionToOptions,
        )
        .map((c) => c.conflictOption.id),
    ),
  ];

  // Find all selected option ids
  const optionIds = (options ?? []).map((o) => o.id);

  // TODO: Maybe show a loading state
  if (!globalOptions) return null;
  return (
    <div css={Css.df.fdc.gap3.$}>
      {Object.entries(optionsPerGroup).map(([group, groupOptions]) => (
        <AccordionGroupField
          key={group}
          label={`Options By ${group}`}
          items={groupBy(groupOptions, (option) => option.location)}
          selectedItemIds={options?.map(({ id }) => id)}
          activeItemId={activeOption?.id}
          disabledItemIds={disabledOptionIds}
          onClickItem={setActiveOption}
        >
          {/* How to render each Checkbox */}
          {(option, context) => {
            // TODO: Move this to it's own component
            // eslint-disable-next-line
            const triggerRef = useRef<HTMLDivElement>(null);
            const { name, priceInCents, optionToOptions: conflicts } = option;
            const { isActive, isSelected, isLast } = context;
            const isDisabled = disabledOptionIds.includes(option.id);

            // Find selected conflicting option names
            const conflictNames = conflicts
              // By selecting only the conflicts which are selected
              .filter((c) => optionIds.includes(c.conflictOption.id))
              // And getting the name of the conflicting option
              .map((c) => c.conflictOption.name);
            const disabledReason = `This option conflicts with ${conflictNames?.join(", ")}.`;

            return (
              <motion.div
                style={{
                  borderLeftWidth: 4,
                  borderLeftStyle: "solid",
                  borderBottom: !isLast ? "1px solid #D9D9D9" : "",
                }}
                animate={{ borderLeftColor: isActive ? "#353535" : "#FFF" }}
                css={Css.py1.px2.df.aifs.jcsb.relative.tl.gap1.if(isDisabled).$}
                ref={triggerRef}
              >
                <motion.p css={Css.sm.$} animate={{ opacity: isDisabled ? 0.5 : 1 }}>
                  {name}
                </motion.p>
                <motion.div css={Css.df.gap1.aic.fs0.$} animate={{ opacity: isDisabled ? 0.5 : 1 }}>
                  <p css={Css.xsEm.$}>
                    {priceInCents !== 0 ? (
                      currencyFormatterWithSign(priceInCents)
                    ) : (
                      <span>
                        TBD<sup>[2]</sup>
                      </span>
                    )}
                  </p>
                  {/* TODO: Find the correct aria label for this and enable this */}
                  <div onClick={() => !isDisabled && toggleOption(option)}>
                    <Icon name={isSelected ? "square-check" : "square-outline"} />
                  </div>
                </motion.div>

                {isDisabled && <Tooltip triggerRef={triggerRef}>{disabledReason}</Tooltip>}
              </motion.div>
            );
          }}
        </AccordionGroupField>
      ))}

      {/* Personalization information */}
      <div css={Css.br4.bgGray200.p2.df.fdc.gap1.$}>
        <div css={Css.df.gap1.$}>
          <div>
            <Icon name="flashlight" />
          </div>
          <p css={Css.gray900.sm.$}>More personalization available once you connect with the Homebound team.</p>
        </div>
      </div>
    </div>
  );
}

type AccordionGroupFieldProps<Item> = Omit<AccordionCheckboxFieldProps<Item>, "items"> & {
  // Each key represents an accordion label and value represents the accordion
  // options
  items?: Record<string, Item[]>;
};
function AccordionGroupField<Item extends HasId>(props: AccordionGroupFieldProps<Item>) {
  const { label, items, selectedItemIds, activeItemId, disabledItemIds, onClickItem, children } = props;

  return (
    <div>
      <label css={Css.tinyEm.mb1.ttu.gray800.$}>{label}</label>

      {/* Accordions */}
      {Object.keys(items ?? {}).map((key) => (
        <AccordionCheckboxField
          key={key}
          label={key}
          items={items?.[key]}
          selectedItemIds={selectedItemIds}
          activeItemId={activeItemId}
          disabledItemIds={disabledItemIds}
          onClickItem={onClickItem}
        >
          {(item, context) => children(item, context)}
        </AccordionCheckboxField>
      ))}
    </div>
  );
}

type AccordionCheckboxFieldProps<Item> = {
  label: string;
  items?: Item[];
  selectedItemIds?: ID[];
  /** Represents the Checkbox row which is actively selected */
  activeItemId?: ID;
  /** Represents the disabled Checkbox rows */
  disabledItemIds?: ID[];
  onClickItem: (item: Item) => void;
  children: (
    item: Item,
    context: {
      isSelected: boolean;
      isActive: boolean;
      isFirst: boolean;
      isLast: boolean;
    },
  ) => ReactNode;
};

function AccordionCheckboxField<Item extends HasId>(props: AccordionCheckboxFieldProps<Item>) {
  const { label, items, selectedItemIds, activeItemId, disabledItemIds, onClickItem, children } = props;

  const [isOpen, setIsOpen] = useState(false);

  // When `activeItemId` changes, check to see if this accordion has a child
  // with the given ID. If so, open the accordion.
  useEffect(() => {
    if (activeItemId && items) {
      const item = items.find((item) => item.id === activeItemId);
      if (item) setIsOpen(true);
    }
  }, [activeItemId, items]);

  const selectedCount = items?.filter((item) => selectedItemIds?.includes(item.id)).length ?? 0;

  return (
    <div css={Css.bshBasic.$}>
      {/* Header */}
      <button css={Css.w100.p2.df.aic.jcsb.bgWhite.bt.bb.bc("#D9D9D9").$} onClick={() => setIsOpen(!isOpen)}>
        <span css={Css.baseEm.$}>{label}</span>
        <div css={Css.df.gap2.aic.$}>
          <span css={Css.xs.$}>{`${selectedCount} / ${items?.length ?? 0}`}</span>
          <Icon name={isOpen ? "arrow-up" : "arrow-down"} />
        </div>
      </button>
      {/* Options */}
      {/* TODO: Add motion.div to animate open and close via a `layout` prop */}
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: isOpen ? 1 : 0,
          height: isOpen ? "auto" : 0,
          display: isOpen ? "block" : "none",
        }}
        transition={
          isOpen
            ? { duration: 0.2, opacity: { delay: 0.2 }, display: { delay: 0.4 } }
            : { duration: 0.2, height: { delay: 0.2 }, display: { delay: 0.4 } }
        }
      >
        {items?.map((item, index) => {
          const context = {
            isSelected: selectedItemIds?.includes(item.id) ?? false,
            isActive: item.id === activeItemId,
            isFirst: index === 0,
            isLast: index === items?.length - 1,
            isDisabled: disabledItemIds?.includes(item.id) ?? false,
          };
          return (
            <button
              key={item.id}
              onClick={() => onClickItem(item)}
              css={Css.w100.if(context.isDisabled).cursorNotAllowed.$}
              disabled={context.isDisabled}
            >
              {children(item, context)}
            </button>
          );
        })}
      </motion.div>
    </div>
  );
}

type RightPaneProps = {
  // The currently active option.
  option?: OptionFragment;
  configurationValues: OptionsConfigurationValues;
  configurationActions: OptionsConfigurationActions;
};
function RightPane(props: RightPaneProps) {
  const {
    // Represents the active option that will be shown on the right pane
    option,
    configurationValues,
    configurationActions,
  } = props;
  const {
    // Represents the selected options
    options,
  } = configurationValues;
  const { toggleOption } = configurationActions;

  const {
    image,
    floorPlanImage,
    name,
    description,
    priceInCents,
    baseSqft,
    bathrooms,
    bedrooms,
    garageParking,
    floor,
  } = option ?? {};
  const isSelected = !!options?.find((o) => o.id === option?.id);

  // Counts the number of stats/details to determine the correct CSS
  // `grid-column-end` to use for the price stat.
  const statsCount = useMemo(() => {
    let count = 1;
    if (isValidOptionDetail(baseSqft)) count++;
    if (isValidOptionDetail(bathrooms)) count++;
    if (isValidOptionDetail(bedrooms)) count++;
    if (isValidOptionDetail(garageParking)) count++;
    return count;
  }, [baseSqft, bathrooms, bedrooms, garageParking]);

  if (!option) return null;

  return (
    <div css={Css.df.fdc.w100.$}>
      {/* TODO: Potential carousel component if there are more than one image */}
      {image && (
        <>
          <div css={Css.w100.relative.hPx(576).$}>
            <img css={Css.w100.objectCover.hPx(576).$} src={image} alt={name} />
            {/* Disclaimer */}
          </div>
          <p css={Css.gray600.tiny.tr.mt1.mr1.$}>
            <em>
              Renderings are for representational purposes only. Layout, specifications, and finishes may vary or
              change.
            </em>
          </p>
        </>
      )}

      {/* Bottom Content */}
      <div css={Css.pxPx(8 * 12).pt4.$}>
        {/* Heading */}
        <div css={Css.df.aifs.jcsb.mb2.gap4.$}>
          <h1 css={Css.xl3Em.$}>{name}</h1>
          <div css={Css.fs0.$}>
            <Button onClick={() => toggleOption(option)} variant={isSelected ? "secondary" : "primary"}>
              {isSelected ? "Remove Option" : "Add Option"}
            </Button>
          </div>
        </div>

        {/* About and Stats */}
        <div css={Css.mb3.$}>
          <h2 css={Css.xlEm.mb1.$}>About</h2>
          {/* Row with the description and stats */}
          <div css={Css.df.fdr.jcsb.gap4.$}>
            {/* Description */}
            <div css={Css.maxwPx(400).$}>
              <p css={Css.sm.mb2.$}>
                {description ??
                  "More information about this option can be provided by a Homebound Representative after completing the reservation process."}
                <sup>[1]</sup>
              </p>
              <p css={Css.xsEm.gray700.$}>
                [1] Option availability and details may vary based on plan and design package selected. To be confirmed
                with your sales representative.
              </p>
              {/* Show TBD** footnote when no pricing information is available. */}
              {priceInCents === 0 && <p css={Css.xsEm.gray700.mt1.$}>[2] Pricing to be confirmed with Homebound.</p>}
            </div>
            {/* Stats */}
            <div css={Css.bl.bGray300.pl7.fg1.df.aifs.jcc.$}>
              <div css={Css.dg.gtc("1fr 1fr").gap4.$}>
                {/* Price */}
                <div css={Css.nowrap.df.fdr.aic.gap2.if(statsCount === 1).add("gridColumnEnd", "-1").$}>
                  <Icon name="square-dollar" />
                  <div css={Css.df.fdc.$}>
                    <p css={Css.tinyEm.ttu.gray700.$}>Price</p>
                    <p css={Css.xl2Em.$}>
                      {priceInCents !== 0 ? (
                        currencyFormatterWithSign(priceInCents ?? 0)
                      ) : (
                        <span>
                          TBD<sup>[2]</sup>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                {/* Options Stats */}
                {/* SQFT */}
                {isValidOptionDetail(baseSqft) && (
                  <Detail label="Added Square FT" icon="sqft" value={baseSqft ? `+${baseSqft}` : ""} />
                )}
                {/* Bedrooms */}
                {isValidOptionDetail(bedrooms) && (
                  <Detail label="Added Bedrooms" icon="bed" value={bedrooms ? `+${bedrooms}` : ""} />
                )}
                {/* Bathrooms */}
                {isValidOptionDetail(bathrooms) && (
                  <Detail label="Added Bathrooms" icon="faucet" value={bathrooms ? `+${bathrooms}` : ""} />
                )}
                {/* Garage Parking */}
                {isValidOptionDetail(garageParking) && (
                  <Detail
                    label="Added Parking"
                    icon="square-parking"
                    value={garageParking ? `+${garageParking}` : ""}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Note */}
        {floorPlanImage && (
          <div css={Css.df.gap1.aic.jcfe.mb1.$}>
            {/* Circle */}
            <div css={Css.h2.w2.bgColor("#E0D2C8").br100.bsSolid.bc("#000").bw1.$} />
            <p css={Css.xs.gray700.$}>Represents an option at an additional cost.</p>
          </div>
        )}

        {/* Floor Plan - use option floor plan image when exists otherwise fallback to floor image */}
        {floor && FloorPlanCard({ ...floor, image: floorPlanImage ?? floor.image })}
      </div>
      <NextCTA to="/summary" heading="Next: Reserve" subheading="Review your summary and reserve your home." />
    </div>
  );
}
