import { ReactNode } from "react";
import { Css } from "~generated/css";
import { HasId } from "~utils";

type Context = {
  selected: boolean;
};
type RadioFieldProps<Option> = {
  children: (option: Option, state: Context) => ReactNode;
  label?: string;
  onChange: (value: Option) => void;
  options?: Option[];
  /** (Optional) Test id which will suffix the `option.id` (e.g `radio-option-1`) */
  testId?: string;
  value?: string;
};

// TODO: Possible update this code with the code below for ARIA support.
export function RadioField<Option extends HasId>(props: RadioFieldProps<Option>) {
  const { children, label, onChange, options, testId, value } = props;

  return (
    <div css={Css.df.fdc.$}>
      {label && <label css={Css.tinyEm.mb1.ttu.gray800.$}>{label}</label>}

      <div css={Css.df.gap2.add("flexWrap", "wrap").$}>
        {options?.map((option) => {
          const { id } = option;
          const context = { selected: id === value };
          return (
            <button
              css={Css.cursorPointer.tl.$}
              data-testid={`${testId}-option-${id}`}
              key={`${testId}-option-${id}`}
              onClick={() => onChange(option)}
            >
              {children(option, context)}
            </button>
          );
        })}
      </div>
    </div>
  );
}

// type RadioFieldProps<Option> = RadioGroupProps & {
//   label: string;
//   items: Iterable<Option>;
//   children: (option: Option, state: { selected: boolean; isFocusVisible: boolean }) => ReactNode;
// };
// function RadioField<Option extends { id: string }>(props: RadioFieldProps<Option>) {
//   const { label, items, children } = props;
//   const state = useRadioGroupState(props);
//   const { radioGroupProps, labelProps } = useRadioGroup(props, state);

//   return (
//     <div {...radioGroupProps} css={Css.df.fdc.$}>
//       <label css={Css.tinyEm.mb1.$} {...labelProps}>
//         {label}
//       </label>

//       <div css={Css.dg.gtc("1fr 1fr").gap2.$}>
//         {[...items].map((item) => (
//           <Radio key={item.id} state={state} item={item} children={children} />
//         ))}
//       </div>
//     </div>
//   );
// }

// type RadioProps<Option> = Omit<AriaRadioProps, "children" | "value"> & {
//   state: RadioGroupState;
//   item: Option;
//   children: RadioFieldProps<Option>["children"];
// };
// function Radio<Option extends { id: string }>(props: RadioProps<Option>) {
//   const { state, item, children, ...otherProps } = props;

//   const ref = useRef(null);

//   const { inputProps } = useRadio({ value: item.id, ...otherProps, "aria-labelledby": item.id }, state, ref);

//   const { focusProps, isFocusVisible } = useFocusRing();

//   const isSelected = state.selectedValue === item.id;
//   return (
//     <label css={Css.cursorPointer.$}>
//       <VisuallyHidden>
//         <input {...mergeProps(inputProps, focusProps)} ref={ref} aria-labelledby={item.id} />
//       </VisuallyHidden>
//       <div id={item.id}>{children(item, { selected: isSelected, isFocusVisible })}</div>
//     </label>
//   );
// }
