/** Renders SVG icons from a list of icons */
export type IconProps = { name: keyof typeof icons };
export function Icon(props: IconProps) {
  const { name } = props;
  return icons[name];
}

const icons = {
  flashlight: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon / flashlight">
        <path
          id="Vector"
          d="M7.76502 13.4721L9.88304 15.5901V19.1201H14.1191V15.5901L16.2371 13.4721V9.94205H7.76502V13.4721ZM11.2951 5H12.7071V7.11802H11.2951V5ZM6 7.73578L6.99829 6.73678L8.49503 8.23492L7.49673 9.23392L6 7.73578ZM15.5029 8.23704L17.0017 6.74031L18 7.7386L16.5019 9.23604L15.5029 8.23704Z"
          fill="#646464"
        />
      </g>
    </svg>
  ),
  cart: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="actions / reserve">
        <path
          id="Vector"
          d="M21.822 7.431C21.635 7.161 21.328 7 21 7H7.333L6.179 4.23C5.867 3.482 5.143 3 4.333 3H2V5H4.333L9.077 16.385C9.232 16.757 9.596 17 10 17H18C18.417 17 18.79 16.741 18.937 16.352L21.937 8.352C22.052 8.044 22.009 7.7 21.822 7.431ZM17.307 15H10.667L8.167 9H19.557L17.307 15Z"
          fill="#FEFEFE"
        />
        <path
          id="Vector_2"
          d="M10.5 21C11.3284 21 12 20.3284 12 19.5C12 18.6716 11.3284 18 10.5 18C9.67157 18 9 18.6716 9 19.5C9 20.3284 9.67157 21 10.5 21Z"
          fill="#FEFEFE"
        />
        <path
          id="Vector_3"
          d="M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z"
          fill="#FEFEFE"
        />
      </g>
    </svg>
  ),
  "circle-check-large": (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33 5.5C17.8365 5.5 5.5 17.8365 5.5 33C5.5 48.1635 17.8365 60.5 33 60.5C48.1635 60.5 60.5 48.1635 60.5 33C60.5 17.8365 48.1635 5.5 33 5.5ZM33 55C20.8698 55 11 45.1302 11 33C11 20.8698 20.8698 11 33 11C45.1302 11 55 20.8698 55 33C55 45.1302 45.1302 55 33 55Z"
        fill="#242424"
      />
      <path
        d="M27.4982 37.3643L21.176 31.053L17.293 34.947L27.5037 45.1358L45.9452 26.6943L42.0567 22.8058L27.4982 37.3643Z"
        fill="#242424"
      />
    </svg>
  ),
  "circle-check": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="actions / checkmark-checked">
        <g id="check">
          <rect id="Rectangle 41" x="4" y="4" width="16" height="16" rx="8" fill="#242424" />
          <path
            id="Vector 1"
            d="M9 12.7273L11.2857 15L15 10"
            stroke="#FEFEFE"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  ),
  "circle-outline": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="actions / checkmark-unchecked">
        <g id="toggle">
          <rect
            id="Rectangle 41"
            x="4.7"
            y="4.7"
            width="14.6"
            height="14.6"
            rx="7.3"
            stroke="#C9C9C9"
            strokeWidth="1.4"
          />
        </g>
      </g>
    </svg>
  ),
  "square-check": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V16C20 18.2091 18.2091 20 16 20H8C5.79086 20 4 18.2091 4 16V8Z"
        fill="#242424"
      />
      <path
        d="M10.6663 14.3907L8.47099 12.1954L7.52832 13.1381L10.6663 16.2761L17.1377 9.80473L16.195 8.86206L10.6663 14.3907Z"
        fill="#FEFEFE"
      />
      <path
        d="M8 5H16V3H8V5ZM19 8V16H21V8H19ZM16 19H8V21H16V19ZM5 16V8H3V16H5ZM8 19C6.34315 19 5 17.6569 5 16H3C3 18.7614 5.23858 21 8 21V19ZM19 16C19 17.6569 17.6569 19 16 19V21C18.7614 21 21 18.7614 21 16H19ZM16 5C17.6569 5 19 6.34315 19 8H21C21 5.23858 18.7614 3 16 3V5ZM8 3C5.23858 3 3 5.23858 3 8H5C5 6.34315 6.34315 5 8 5V3Z"
        fill="#242424"
      />
    </svg>
  ),
  "square-outline": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V16C20 18.2091 18.2091 20 16 20H8C5.79086 20 4 18.2091 4 16V8Z"
        fill="#FEFEFE"
      />
      <path
        d="M8 5H16V3H8V5ZM19 8V16H21V8H19ZM16 19H8V21H16V19ZM5 16V8H3V16H5ZM8 19C6.34315 19 5 17.6569 5 16H3C3 18.7614 5.23858 21 8 21V19ZM19 16C19 17.6569 17.6569 19 16 19V21C18.7614 21 21 18.7614 21 16H19ZM16 5C17.6569 5 19 6.34315 19 8H21C21 5.23858 18.7614 3 16 3V5ZM8 3C5.23858 3 3 5.23858 3 8H5C5 6.34315 6.34315 5 8 5V3Z"
        fill="#DDDCDC"
      />
    </svg>
  ),
  "arrow-next": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Arrow / next">
        <g id="arrows / arrow / back">
          <path
            id="Vector"
            d="M3.00006 13L17.5861 13L12.2931 18.293L13.7071 19.707L21.4141 12L13.7071 4.29303L12.2931 5.70703L17.5861 11L3.00006 11L3.00006 13Z"
            fill="#FEFEFE"
          />
        </g>
      </g>
    </svg>
  ),
  "arrow-back": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Arrow / back">
        <path id="Vector" d="M20 13L20 11L8 11L8 8L4 12L8 16L8 13L20 13Z" fill="#353535" />
      </g>
    </svg>
  ),
  "arrow-up": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Arrow / up">
        <g id="Chevron/up">
          <path
            id="Shape"
            d="M15.0274 14L11.6996 10.9467L8.37186 14L7.34961 13.06L11.6996 9.06L16.0496 13.06L15.0274 14Z"
            fill="#353535"
          />
        </g>
      </g>
    </svg>
  ),
  "arrow-down": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Arrow / down">
        <g id="Chevron/up">
          <path
            id="Shape"
            d="M8.97263 10L12.3004 13.0533L15.6281 10L16.6504 10.94L12.3004 14.94L7.95038 10.94L8.97263 10Z"
            fill="#353535"
          />
        </g>
      </g>
    </svg>
  ),
  "water-small": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Home / water-small">
        <g id="Home / water">
          <line id="Line 5" x1="11.25" y1="4" x2="17.25" y2="4" stroke="#353535" />
          <path id="Vector 58" d="M14.25 4.5L14.25 8.25" stroke="#353535" />
          <path id="Vector 59" d="M8.25 11.5H7.75V12V14.5H5V8.75H19.75V11.5H8.25Z" stroke="#353535" />
          <path
            id="Ellipse 4"
            d="M7.375 18.75C7.375 19.1539 7.24815 19.3837 7.10023 19.5175C6.94381 19.659 6.70039 19.75 6.375 19.75C6.04961 19.75 5.80619 19.659 5.64977 19.5175C5.50185 19.3837 5.375 19.1539 5.375 18.75C5.375 18.6066 5.4291 18.421 5.54897 18.1998C5.66648 17.983 5.82949 17.7643 6.00298 17.564C6.13234 17.4146 6.26256 17.2811 6.375 17.1724C6.48744 17.2811 6.61766 17.4146 6.74702 17.564C6.92051 17.7643 7.08352 17.983 7.20103 18.1998C7.3209 18.421 7.375 18.6066 7.375 18.75Z"
            stroke="#353535"
          />
        </g>
      </g>
    </svg>
  ),
  faucet: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Home / water">
        <line id="Line 5" x1="11" y1="1.5" x2="19" y2="1.5" stroke="#353535" />
        <path id="Vector 58" d="M15 2L15 7" stroke="#353535" />
        <path id="Vector 59" d="M7 11.5H6.5V12V15.5H2.5V7.5H22.5V11.5H7Z" stroke="#353535" />
        <path
          id="Ellipse 4"
          d="M6 21C6 21.5765 5.81719 21.9313 5.57878 22.147C5.33188 22.3703 4.96347 22.5 4.5 22.5C4.03653 22.5 3.66812 22.3703 3.42122 22.147C3.18281 21.9313 3 21.5765 3 21C3 20.7703 3.08535 20.4947 3.25209 20.187C3.41648 19.8837 3.64199 19.5821 3.87798 19.3095C4.10099 19.052 4.32536 18.8296 4.5 18.6667C4.67463 18.8296 4.89901 19.052 5.12202 19.3095C5.35801 19.5821 5.58352 19.8837 5.74791 20.187C5.91465 20.4947 6 20.7703 6 21Z"
          stroke="#353535"
        />
      </g>
    </svg>
  ),
  "bed-small": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Home / bed-small">
        <g id="Home / Bed">
          <rect id="Rectangle 106" x="4.25" y="11.75" width="15.5" height="5" stroke="#353535" />
          <rect id="Rectangle 107" x="5.75" y="4.25" width="12.5" height="7.25" stroke="#353535" />
          <rect id="Rectangle 108" x="9.5" y="8" width="5" height="3.5" stroke="#353535" />
          <path id="Vector 56" d="M6 17.25V20.25" stroke="#353535" />
          <path id="Vector 57" d="M18 17.25V20.25" stroke="#353535" />
        </g>
      </g>
    </svg>
  ),
  bed: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Home / Bed">
        <rect id="Rectangle 106" x="1.5" y="11.5" width="21" height="7" stroke="#353535" />
        <rect id="Rectangle 107" x="3.5" y="1.5" width="17" height="10" stroke="#353535" />
        <rect id="Rectangle 108" x="8.5" y="6.5" width="7" height="5" stroke="#353535" />
        <path id="Vector 56" d="M4 19V23" stroke="#353535" />
        <path id="Vector 57" d="M20 19V23" stroke="#353535" />
      </g>
    </svg>
  ),
  "square-parking": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Home / garage">
        <g id="Group 1283">
          <g id="Home / garage_2">
            <rect id="Rectangle 105" x="1.5" y="1.5" width="21" height="21" stroke="#353535" />
            <path
              id="P"
              d="M8.33301 17.4267H9.46234V13.2302H12.722C14.9422 13.2302 16.0587 12.1137 16.0587 10.3042C16.0587 8.49475 14.9422 7.41675 12.722 7.41675H8.33301V17.4267ZM9.46234 12.1779V8.46908H12.4782C14.1208 8.46908 14.878 9.16208 14.878 10.3171C14.878 11.4849 14.1465 12.1779 12.5423 12.1779H9.46234Z"
              fill="#353535"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  sqft: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Home / Lot-purchase">
        <path id="Vector 1" d="M1.45801 6.95825V1.45825H6.95801" stroke="#353535" />
        <path id="Vector 4" d="M6.95801 22.5417L1.45801 22.5417L1.45801 17.0417" stroke="#353535" />
        <path id="Vector 2" d="M17.042 1.45825L22.542 1.45825L22.542 6.95825" stroke="#353535" />
        <path id="Vector 3" d="M22.542 17.0417L22.542 22.5417L17.042 22.5417" stroke="#353535" />
      </g>
    </svg>
  ),
  "square-dollar": (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Home / financing">
        <path id="Vector" d="M1.5 1.5H22.5V22.5H1.5V1.5Z" stroke="#353535" />
        <path
          id="$"
          d="M15.303 13.7172C15.303 11.9899 13.9091 11.7374 12.5758 11.5859V8.79798C13.6061 8.91919 14.1919 9.51515 14.2727 10.4646H15.202C15.202 9.09091 14.1313 8.11111 12.5758 7.9697V7H11.7475V7.9697C10.2121 8.11111 9.21212 9 9.21212 10.1818C9.21212 11.8788 10.4747 12.1717 11.7475 12.3333V15.303C10.6364 15.1515 10 14.4646 9.9596 13.3939H9C9 15 10.0808 15.9798 11.7475 16.1313V17H12.5758V16.1414C14.3434 16.0101 15.303 15 15.303 13.7172ZM10.1414 10.3232C10.1414 9.44444 10.7071 8.89899 11.7475 8.78788V11.4949C10.8485 11.3737 10.1414 11.1515 10.1414 10.3232ZM12.5758 15.3232V12.4242C13.5556 12.5455 14.3737 12.7576 14.3737 13.7071C14.3737 14.6465 13.7374 15.2323 12.5758 15.3232Z"
          fill="black"
        />
      </g>
    </svg>
  ),
  home: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 19.5L1.5 12.2546L12 4.61825L22.5 12.2546L22.5 19.5L1.5 19.5Z" stroke="#353535" />
      <path d="M19.5 5.5V9.75422L16.5 7.57892V5.5H19.5Z" stroke="#353535" strokeLinecap="round" />
      <path
        d="M13.5 19.5H10.5V16C10.5 15.1716 11.1716 14.5 12 14.5C12.8284 14.5 13.5 15.1716 13.5 16V19.5Z"
        stroke="#353535"
        strokeLinecap="round"
      />
    </svg>
  ),
  basement: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0012 3.39746H16.9652V7.8008H11.9929V12.5246H6.9887V17.2074H2V22.0132H22.0012V3.39746Z"
        stroke="#353535"
        strokeMiterlimit="10"
      />
      <path d="M12.5388 2L2 12.9762V7.88261" stroke="#353535" strokeMiterlimit="10" strokeLinejoin="round" />
    </svg>
  ),
  thermometer: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0307 1C11.3583 1 10.7133 1.26749 10.2383 1.74358C9.76317 2.2195 9.49701 2.86492 9.49821 3.53741V13.7106C8.24274 14.44 7.36465 15.6771 7.09043 17.1029C6.81608 18.5287 7.17274 20.0033 8.06805 21.1464C8.96336 22.2893 10.3099 22.9886 11.7597 23.0638H12.0307C13.5114 23.0636 14.9163 22.4099 15.87 21.2773C16.8237 20.1448 17.2287 18.6491 16.9768 17.19C16.7248 15.7311 15.8415 14.4577 14.5633 13.7106V3.53741C14.5645 2.86492 14.2983 2.21954 13.8233 1.74358C13.3481 1.26749 12.7033 1 12.0308 1H12.0307ZM16.5707 18.0274C16.5707 19.2761 16.0564 20.4695 15.1489 21.3272C14.2415 22.1847 13.0209 22.6307 11.7742 22.5601C10.4498 22.4888 9.22293 21.8421 8.41576 20.7897C7.60858 19.7373 7.30195 18.3844 7.57663 17.0869C7.85115 15.7895 8.67939 14.6768 9.84373 14.0417L9.96836 13.9746V3.53757C9.99278 2.82122 10.3893 2.16951 11.0139 1.81834C11.6387 1.46703 12.4014 1.46703 13.0262 1.81834C13.6508 2.16947 14.0473 2.82122 14.0717 3.53757V13.9844L14.1964 14.0515C14.9125 14.4405 15.5106 15.0147 15.9284 15.7143C16.3462 16.4139 16.568 17.2129 16.5707 18.0278L16.5707 18.0274Z"
        fill="#353535"
        stroke="#353535"
        strokeWidth="0.5"
      />
      <path
        d="M12.2706 16.3055V7.80381C12.2706 7.67132 12.1632 7.56392 12.0307 7.56392C11.8982 7.56392 11.7908 7.67132 11.7908 7.80381V16.3055C11.2005 16.3873 10.6924 16.7642 10.443 17.3055C10.1934 17.8467 10.2369 18.4779 10.5582 18.9799C10.8796 19.482 11.4346 19.7856 12.0307 19.7856C12.6267 19.7856 13.1818 19.4819 13.5032 18.9799C13.8245 18.4779 13.868 17.8467 13.6184 17.3055C13.369 16.7642 12.8609 16.3873 12.2706 16.3055ZM12.0307 19.3033C11.6946 19.3033 11.3722 19.1699 11.1345 18.9325C10.8966 18.695 10.7626 18.373 10.762 18.0369C10.7615 17.7008 10.8941 17.3783 11.131 17.1401C11.3681 16.9017 11.6899 16.7672 12.0258 16.7659C12.3619 16.7647 12.6848 16.8967 12.9235 17.1332C13.1622 17.3698 13.2974 17.6913 13.2994 18.0274C13.3006 18.365 13.1676 18.689 12.9297 18.9284C12.6917 19.1678 12.3683 19.3026 12.0307 19.3033Z"
        fill="#353535"
        stroke="#353535"
        strokeWidth="0.5"
      />
    </svg>
  ),
  cutlery: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2518 22.364V2.28369C20.2518 2.28369 16.4531 2.97275 16.4531 7.97301C16.4531 9.97433 16.9629 11.3695 17.5756 12.3232"
        stroke="#353535"
        strokeMiterlimit="10"
      />
      <path d="M5.86328 2.05817V7.4243" stroke="#353535" strokeMiterlimit="10" />
      <path d="M8.56117 2.05817V8.49509L5.77954 11.4489V22.3641" stroke="#353535" strokeMiterlimit="10" />
      <path d="M3 2V8.43692L5.78163 11.3907" stroke="#353535" strokeMiterlimit="10" />
    </svg>
  ),
  tree: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8C19 11.866 15.866 15 12 15C8.13401 15 5 11.866 5 8C5 4.13401 8.13401 1 12 1C15.866 1 19 4.13401 19 8ZM20 8C20 12.4183 16.4183 16 12 16C7.58172 16 4 12.4183 4 8C4 3.58172 7.58172 0 12 0C16.4183 0 20 3.58172 20 8ZM13.5 24C13.7761 24 14 23.7761 14 23.5C14 23.2239 13.7761 23 13.5 23H10.5C10.2239 23 10 23.2239 10 23.5C10 23.7761 10.2239 24 10.5 24H13.5Z"
        fill="#353535"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5C12.2761 4.5 12.5 4.72386 12.5 5V8.0175L14.3711 6.14645C14.5663 5.95118 14.8829 5.95118 15.0782 6.14645C15.2734 6.34171 15.2734 6.65829 15.0782 6.85355L12.5 9.43172L12.5 23C12.5 23.2761 12.2761 23.5 12 23.5C11.7239 23.5 11.5 23.2761 11.5 23L11.5 9.77526C11.4535 9.75188 11.4099 9.72079 11.3711 9.68198L8.54269 6.85355C8.34742 6.65829 8.34742 6.34171 8.54269 6.14645C8.73795 5.95118 9.05453 5.95118 9.24979 6.14645L11.5 8.39665L11.5 5C11.5 4.72386 11.7239 4.5 12 4.5Z"
        fill="#353535"
      />
    </svg>
  ),
  office: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.46741 10V3H17.0427V10" stroke="#353535" strokeMiterlimit="10" />
      <path d="M18.0973 13.0217H5.40881V16.9502H18.0973V13.0217Z" stroke="#353535" strokeMiterlimit="10" />
      <path d="M17.0419 10.1804V13.0216" stroke="#353535" strokeMiterlimit="10" />
      <path d="M6.52063 10.1522V12.9934" stroke="#353535" strokeMiterlimit="10" />
      <path d="M12.092 16.9504V21.8008" stroke="#353535" strokeMiterlimit="10" />
      <path d="M7.14417 21.8005H16.8921" stroke="#353535" strokeMiterlimit="10" />
      <path d="M1.84448 10.0062V22.0754" stroke="#353535" strokeMiterlimit="10" />
      <path d="M24 9.9541H0" stroke="#353535" strokeMiterlimit="10" />
      <path d="M22.1542 10.1576V22.2315" stroke="#353535" strokeMiterlimit="10" />
    </svg>
  ),
  shelves: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.5 2.5H22.5V21.99H21.5V3.5H2.5V21.99H1.5V2.5Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22 9.57324H2V8.57324H22V9.57324Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22 16.2399H2V15.2399H22V16.2399Z" fill="black" />
    </svg>
  ),
};
