import { useEffect } from "react";
import { Navigate, RouteProps, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AnimatedRoutes, Header } from "~components";
import { isFullAccessUser, setLocalStorageKey, useConfiguration } from "~contexts";
import { Css } from "~generated/css";
import { ArchitectureStep, InteriorStep, LotStep, OptionsStep, SummaryStep } from "./steps";
import { redirectReplace } from "./utils/window";

const redirectUrl = "https://www.homebound.com/rebuild-marshall-fire";

export const NotReservedConfigurationRoutes: RouteProps[] = [
  { path: "/architecture", element: <ArchitectureStep /> },
  { path: "/interior", element: <InteriorStep /> },
  { path: "/options", element: <OptionsStep /> },
  { path: "/summary", element: <SummaryStep /> },
];

const ReservedConfigurationRoutes: RouteProps[] = [
  { path: "/summary/:code", element: <SummaryStep /> },
  // TODO: Temporarily placing this here so that it does not appear in the nav.
  { path: "/lot", element: <LotStep /> },
];

export default function App() {
  /** Redirect Handler **/
  // Note: See README Authorization section
  // This hook will determine if we should redirect to `/summary/:code`
  useAppRedirect();

  return (
    <div css={Css.mh("100vh").df.fdc.$}>
      <Header />

      <main css={Css.fg1.df.$}>
        <AnimatedRoutes>
          {[
            ...NotReservedConfigurationRoutes,
            ...ReservedConfigurationRoutes,
            { path: "*", element: <Navigate to={NotReservedConfigurationRoutes[0].path!} /> },
          ]}
        </AnimatedRoutes>
      </main>
    </div>
  );
}

/** *** Helpers *****/
/**
 * Handles app access based on localStorage and redirecting
 * to the `/summary/:code` step when sessionStorage contains
 * a reserved configuration.
 */
export function useAppRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  const { code } = useConfiguration();
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("checkoutFullAccess");

  // set local storage when ?checkoutFullAccess=hb-sales and localStorage key doesn't already exist
  if (queryValue && queryValue === "hb-sales" && !isFullAccessUser()) {
    setLocalStorageKey("checkoutFullAccess", "hb-sales");
  }

  // clear local storage when ?checkoutFullAccess=none (mainly used for testing)
  if (queryValue && queryValue === "none") {
    setLocalStorageKey("checkoutFullAccess", "none");
  }

  useEffect(() => {
    // After the component mounts, redirect to the `/summary` step if the configuration is reserved
    // Note: when sessionStorage does not contain code but user is directly accessing /summary/123 then we allow this and set code in sessionStorage
    if (code) {
      // allow user to access their summary page
      navigate(`/summary/${code}`);
    } else if (
      // redirect user when localStorage key/value doesn't exist and not on summary page
      !isFullAccessUser() &&
      !location.pathname.includes("/summary/")
    ) {
      redirectReplace(redirectUrl);
    }
  }, [code, location.pathname, navigate]);
}
