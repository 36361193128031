import { motion, Variants } from "framer-motion";
import { ReactNode } from "react";
import { Css } from "~generated/css";

/** *** Types *****/
export type SummaryProps = {
  // Animated vertical cards which contains animated line items
  groups: Array<{
    title: string;
    // Icon which is displayed to the right of the title
    icon: ReactNode;
    lineItems: Array<{
      // Shown on the left side of the line item
      title: string;
      subTitle?: string;
      // Shown on the right side of the line item
      price: ReactNode;
      subPrice?: string;
    }>;
  }>;
  // Array of components which appear under the total horizontal line.
  totalComponents: ReactNode[];
};

/** Animation Variants **/
const groupsVariants: Variants = {
  visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
};
const groupContainerVariants: Variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};
const lineItemContainerVariants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const lineItemVariants: Variants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0, transition: { bounce: false } },
};

/** Renders a list of animated cards with line items and costs */
export function Summary(props: SummaryProps) {
  const { groups, totalComponents } = props;

  return (
    // Groups Container
    <motion.div css={Css.df.fdc.gap2.$} variants={groupsVariants} initial="hidden" animate="visible">
      {groups.map((group) => {
        const { title, icon, lineItems } = group;

        return (
          // Group Container
          <motion.div css={Css.br4.bshHover.bgWhite.p2.$} variants={groupContainerVariants} key={title}>
            {/* Heading */}
            <div css={Css.df.fdr.aic.jcsb.mb2.$}>
              <h2 css={Css.lgEm.$}>{title}</h2>
              {icon}
            </div>

            {/* Line Items Container */}
            <motion.div css={Css.df.fdc.gap2.$} variants={lineItemContainerVariants}>
              {lineItems.map((lineItem) => {
                const { title, subTitle, price, subPrice } = lineItem;

                return (
                  // Line Item
                  <motion.div key={title} css={Css.df.jcsb.gap2.$} variants={lineItemVariants}>
                    {/* Left */}
                    <div css={Css.df.fdc.$}>
                      <h3 css={Css.base.$}>{title}</h3>
                      <p css={Css.xs.gray700.$}>{subTitle}</p>
                    </div>
                    {/* Right */}
                    <div css={Css.df.fdc.aife.$}>
                      <p css={Css.base.fs0.$}>{price}</p>
                      {subPrice && <p css={Css.xs.gray700.$}>{subPrice}</p>}
                    </div>
                  </motion.div>
                );
              })}
            </motion.div>
          </motion.div>
        );
      })}

      {/* Totals Container */}
      <motion.div css={Css.mt3.bt.bGray700.pt2.pb1.bt.mt2.df.fdc.gap1.$} variants={groupContainerVariants}>
        {totalComponents}
      </motion.div>
    </motion.div>
  );
}
