import { Interpolation, Theme } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";
import { RefObject, useEffect, useState } from "react";
import { Css } from "~generated/css";

type CustomPlacement = {
  top?: number | string;
  bottom?: number | string;
  right?: number | string;
  left?: number | string;
};
type Placement = "right" | CustomPlacement;
export type TooltipProps = {
  children: string;
  triggerRef: RefObject<HTMLElement>;
  /** Can be either an naive placement or a custom placement */
  placement?: Placement;
};

/**
 * Generic tooltip component which will render it's children as a string and
 * will appear when the trigger is hovered.
 */
export function Tooltip(props: TooltipProps) {
  const { children, triggerRef, placement = "right" } = props;
  const [isVisible, setIsVisible] = useState(false);

  // Update trigger listeners when triggerRef changes.
  useEffect(() => {
    const showTooltip = () => setIsVisible(true);
    const hideTooltip = () => setIsVisible(false);
    const currentTrigger = triggerRef.current;

    // Add event listener to the triggerRef
    currentTrigger?.addEventListener("pointerenter", showTooltip);
    currentTrigger?.addEventListener("pointerleave", hideTooltip);

    // Remove event listeners when the component is unmounted
    return () => {
      currentTrigger?.removeEventListener("pointerenter", showTooltip);
      currentTrigger?.removeEventListener("pointerleave", hideTooltip);
    };
  }, [triggerRef]);

  const placementStyles = getPlacementStyles(placement);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          css={[Css.absolute.bgGray900.px1.pyPx(4).bshModal.br4.w("fit-content").nowrap.df.aic.jcc.$, placementStyles]}
        >
          <span css={Css.white.xsEm.$}>{children}</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function getPlacementStyles(placement: Placement): Interpolation<Theme> {
  // String placement
  if (typeof placement === "string") {
    switch (placement) {
      case "right":
        return Css.left("calc(100% + 8px)").$;
    }
  }

  // Object placement
  const { top = "auto", bottom = "auto", right = "auto", left = "auto" } = placement;
  return Css.top(top).bottom(bottom).right(right).left(left).$;
}
