import { ReactNode } from "react";
import { Css } from "~generated/css";
import { Icon, IconProps } from "./Icon";

type DetailProps = {
  label: string;
  icon?: IconProps["name"];
  value: string | ReactNode;
};
export function Detail(props: DetailProps) {
  const { label, icon, value } = props;

  return (
    <div css={Css.nowrap.df.fdr.aic.gap2.$}>
      {icon && <Icon name={icon} />}
      <div css={Css.df.fdc.$}>
        <p css={Css.tinyEm.ttu.gray700.$}>{label}</p>
        <p css={Css.xl2Em.$}>{value}</p>
      </div>
    </div>
  );
}
