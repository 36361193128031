import { Switcher } from "~components";
import { Css } from "~generated/css";
import { LotType } from "./Lot.step";

type LeftPaneProps = {
  lotTypeContext: {
    lotType: LotType;
    setLotType: (lotType: LotType) => void;
  };
};

export function LeftPane(props: LeftPaneProps) {
  const { lotTypeContext } = props;
  const { lotType, setLotType } = lotTypeContext;

  return (
    <div css={Css.df.fdc.gap3.$}>
      <Switcher label="LOT" items={Object.values(LotType)} selectedItem={lotType} onChange={setLotType} />

      {/* <TextField label="LOCATION" value="Boulder County" readOnly />

      <TextField label="NEIGHBORHOOD" value="Louisville" readOnly /> */}
    </div>
  );
}
