import { useState } from "react";
import { DualPaneLayout, NextCTA } from "~components";
import { Css } from "~generated/css";
import { Bool, Boyl } from "./components";
import { LeftPane } from "./LeftPane";

// TODO: Get this from BE types
export enum LotType {
  BOOL = "I need a lot",
  BOYL = "I have a lot",
}

export function LotStep() {
  const [lotType, setLotType] = useState<LotType>(LotType.BOOL);

  return (
    <DualPaneLayout>
      {{
        left: <LeftPane lotTypeContext={{ lotType, setLotType }} />,
        right: <RightPane lotTypeContext={{ lotType }} />,
      }}
    </DualPaneLayout>
  );
}

type RightPaneProps = {
  lotTypeContext: { lotType: LotType };
};
function RightPane(props: RightPaneProps) {
  const { lotTypeContext } = props;
  const { lotType } = lotTypeContext;

  return (
    <div css={Css.df.fdc.w100.$}>
      {lotType === LotType.BOOL && <Bool />}
      {lotType === LotType.BOYL && <Boyl />}
      <NextCTA
        to="/architecture"
        heading="Next: Choose Your Architecture"
        subheading="Review floorplans that our team recommends for your lot."
      />
    </div>
  );
}
