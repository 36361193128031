import { Icon } from "~components";
import { Css } from "~generated/css";
import { currencyFormatterWithSign } from "~utils";

type SelectionCardProps = {
  size?: "sm" | "lg";
  name: string;
  priceInCents: number;
  // TODO: Find out why the GQL codegen is doing this.
  image?: string | null;
  selected?: boolean;
};

export function SelectionCard(props: SelectionCardProps) {
  const { size = "lg", name, priceInCents, image, selected = false } = props;

  const formattedPrice: string = priceInCents === 0 ? "Included" : currencyFormatterWithSign(priceInCents);

  return (
    <div
      css={
        Css.hPx(size === "sm" ? 100 : 175)
          .wPx(167)
          .br4.bshHover.df.fdc.gap1.overflowHidden.if(!selected)
          .add("opacity", 0.5).$
      }
    >
      <header css={Css.df.fdr.jcsb.pt1.px1.$}>
        {/* Left Column */}
        <div
          css={
            Css.hPx(55)
              .if(size === "sm")
              .h("auto").$
          }
        >
          <p css={Css.smEm.ttc.$}>{name}</p>
          <p css={Css.tinyEm.$}>{formattedPrice}</p>
        </div>
        <Icon name={selected ? "circle-check" : "circle-outline"} />
      </header>
      <div css={Css.fg1.relative.df.add("placeContent", "center").$}>
        <img src={image ?? undefined} alt={name} css={Css.h100.w100.objectCover.absolute.$} />
      </div>
    </div>
  );
}
