import { AnimatePresenceProps, motion } from "framer-motion";
import { useEffect } from "react";
import { Route, RouteProps, Routes, useLocation } from "react-router-dom";
import { Css } from "~generated/css";

/** Animates in and out routes */
type AnimatedRoutesProps = AnimatePresenceProps & { children: RouteProps[] };
export function AnimatedRoutes(props: AnimatedRoutesProps) {
  const { children } = props;
  const location = useLocation();

  // Scroll to the top of the page the `location` changes. This means that
  // we are routing to a new page.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes location={location} key={location.pathname}>
      {children.map(({ path, element, ...otherRouteProps }) => (
        <Route
          key={path}
          path={path}
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.2 } }}
              exit={{ opacity: 0, transition: { duration: 0.2 } }}
              css={Css.fg1.df.$}
            >
              {element}
            </motion.div>
          }
          {...otherRouteProps}
        />
      ))}
    </Routes>
  );
}
