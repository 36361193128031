import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Detail, DualPaneLayout, Icon, PhoneField, Summary, SummaryProps, TextField } from "~components";
import { ConfigurationValues, isFullAccessUser, useConfiguration } from "~contexts";
import { Css } from "~generated/css";
import {
  ConfigurationQuery,
  OptionFragment,
  useConfigurationLazyQuery,
  useSaveConfigurationMutation,
  useSaveConfigurationOptionsMutation,
} from "~generated/graphql";
import { currencyFormatter, dateFormatter, groupBy, isValidOptionDetail } from "~utils";

// Types for the reservation form
type FormState = { name: string; email: string; phoneNumber?: string };
type SetFormState = React.Dispatch<React.SetStateAction<FormState>>;

/**
 * Shows a summary of configuration cost breakdown and selections.
 *
 * This step renders on two paths, either `/summary` or `/summary/:id` which
 * will have slightly different UIs.
 */
export function SummaryStep() {
  /** Router Hooks **/
  const params = useParams(); // Used to get the `/summary/:code` from the URL
  const navigate = useNavigate();

  /** Configuration Query Hook **/
  const [getConfiguration, { data: configurationData, called, error }] = useConfigurationLazyQuery();
  // Handle errors
  useEffect(() => {
    if (error) {
      // When an error occurs getting the given configuration, log the error and
      // navigate to `/architecture` step.
      // TODO: How could we better handle this in the future.
      console.error(`Error getting configuration ${params.code}`, error);
      navigate("/architecture");
    }
  }, [error, navigate, params.code]);

  /** Configuration Mutations Hooks **/
  const [saveConfiguration] = useSaveConfigurationMutation();
  const [saveConfigurationOptions] = useSaveConfigurationOptionsMutation();

  /** Configuration Context Hook **/
  const configuration = useConfiguration();
  const { setConfiguration } = configuration;

  // When on `/summary/123` (reserve step)
  // And we have not queries for configuration details, query it.
  if (params.code && !called) {
    // TODO: verify this eslint ignore
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getConfiguration({ variables: { code: params.code } });
  }

  // Update the ConfigurationContext when querying for a configuration.
  useEffect(() => {
    if (configurationData) setConfiguration(formatConfiguration(configurationData.configuration));
  }, [configurationData, setConfiguration]);

  /** Reservation Form **/
  const [form, setForm] = useState<FormState>({ name: "", email: "", phoneNumber: "" });
  async function onSubmit() {
    const { name, email, phoneNumber } = form;
    const { plan, elevation, elevationStyle, specLevel, specLevelStyle, options } = configuration;

    // There are 2 steps involved to create a configuration:
    // Step 1: Create the configuration
    const { data } = await saveConfiguration({
      variables: {
        input: {
          name,
          email,
          phoneNumber,
          planId: plan?.id,
          elevationId: elevation?.id,
          elevationStyleId: elevationStyle?.id,
          specLevelId: specLevel?.id,
          specLevelStyleId: specLevelStyle?.id,
        },
      },
    });

    const configurationId = data?.saveConfiguration.configuration.id;
    const configurationCode = data?.saveConfiguration.configuration.code ?? "";

    // Step 2: Associate the options to the created configuration
    await saveConfigurationOptions({
      variables: {
        input: {
          configurationToOptions: (options ?? [])
            .map((option) => option.id)
            .map((optionId) => ({
              configurationId,
              optionId,
            })),
        },
      },
    });

    // Redirect to the reserved page
    navigate(`/summary/${configurationCode}`);
  }

  /** Renderer **/
  // Handle redirects to `/architecture` and `/interiors` when missing information
  useEffect(() => {
    // Don't redirect when on `/summary/123` (reserve step) as we expect
    // `elevation` and `specLevel` to be missing due to waiting on networks requests.
    if (params.code) return;

    // When on `/summary` (summary step) and missing `elevation`, go to `/architecture` step
    if (!configuration.elevation) navigate("/architecture");
    // When on `/summary` (summary step) and missing `specLevel`, go to `/interior` step
    else if (!configuration.specLevel) navigate("/interior");
  }, [params, configuration.elevation, configuration.specLevel, navigate]);

  // Don't render the page when missing `elevation` or `specLevel` as these are
  // the basic configuration selections that are used to render the page.
  if (!configuration.elevation || !configuration.specLevel) return null;
  return (
    <DualPaneLayout>
      {{
        left: <LeftPane formContext={{ form, setForm, onSubmit }} configurationValues={configuration} />,
        right: <RightPane configurationValues={configuration} />,
      }}
    </DualPaneLayout>
  );
}

type LeftPaneProps = {
  formContext: { form: FormState; setForm: SetFormState; onSubmit: () => Promise<void> };
  configurationValues: ConfigurationValues;
};
function LeftPane(props: LeftPaneProps) {
  const { formContext, configurationValues } = props;
  const { form, setForm, onSubmit } = formContext;
  const { code, options } = configurationValues;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  return (
    <div css={Css.df.fdc.gap3.$}>
      <h1 css={Css.xl3Em.$}>Summary</h1>

      <Summary {...configurationToSummaryProps(configurationValues)} />

      {!code && (
        <form
          css={Css.df.gap2.fdc.mt2.$}
          onSubmit={(e) => {
            e.preventDefault();
            // TODO: verify this eslint ignore
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onSubmit();
            setIsFormSubmitted(true);
          }}
        >
          <p css={Css.tc.baseEm.$}>
            Email yourself your designed home. We will email you a code to revisit this configuration anytime.
          </p>
          <TextField
            placeholder="Your Name"
            required
            value={form.name}
            onChange={(_, name) => setForm((prevForm) => ({ ...prevForm, name }))}
          />
          <TextField
            placeholder="Your Email"
            required
            type="email"
            value={form.email}
            onChange={(_, email) => setForm((prevForm) => ({ ...prevForm, email }))}
          />
          <PhoneField
            onChange={(phoneNumber) => setForm((prevForm) => ({ ...prevForm, phoneNumber }))}
            placeholder="Your Phone Number"
            value={form.phoneNumber}
          />
          <Button
            disabled={
              !(
                form.name?.length > 0 &&
                form?.email?.match(/.+@.+\..+/) &&
                // if raw phone number is not empty then must be 10 digits
                (form.phoneNumber ? form.phoneNumber.length === 10 : true)
              ) || isFormSubmitted
            }
          >
            {isFormSubmitted ? "Creating Your Configuration..." : "Reserve Now"}
          </Button>
          <p css={Css.sm.mt2.gray700.$}>
            By clicking Reserve Now, your home design will be saved and you will receive a digital code to reference
            your selections. A Homebound representative will connect with you to confirm availability and pricing for
            your home.
          </p>
          <p css={Css.sm.gray700.$}>
            A reservation allows you to connect with Homebound indicating preferences and a place in the queue to get
            your prospective build started. A reservation does not constitute a binding contract for the purchase,
            design, and construction of the property and nothing shall be binding until a definitive formal agreement is
            executed by you and Homebound.
          </p>
          <p css={Css.sm.gray700.$}>
            [1] This is an estimated price and options will need to be confirmed by a sales rep.
          </p>
          {/* Show when there is at least one option with $0 price */}
          {options?.some((option) => option.priceInCents === 0) && (
            <p css={Css.sm.mb8.gray700.$}>[2] Price to be confirmed by Homebound.</p>
          )}
        </form>
      )}
      {code && (
        <>
          <p css={Css.sm.gray700.$}>
            [1] This is an estimated price and options will need to be confirmed by a sales rep.
          </p>
          {/* Show when there is at least one option with $0 price */}
          {options?.some((option) => option.priceInCents === 0) && (
            <p css={Css.sm.gray700.$}>[2] Price to be confirmed by Homebound.</p>
          )}
          <p css={Css.sm.gray700.$}>
            Renderings are for representational purposes only. Layout, specifications, and finishes may vary based on
            selections.
          </p>
          <div css={Css.df.fdc.mt4.aic.$}>
            {/* TODO: Get bigger circle icon */}
            <Icon name="circle-check-large" />
            <p css={Css.lg.$}>Success!</p>
            <p css={Css.lg.$}>
              Your code is:{" "}
              <Link to={`/summary/${code}`} css={Css.color("#353535").important.underline.$}>
                {code}
              </Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
}

// TODO: It might be easiest to just listen to the context vs passing this data down.
type RightPaneProps = {
  configurationValues: Omit<ConfigurationValues, "canReserve" | "priceInCents">;
};
function RightPane(props: RightPaneProps) {
  const { configurationValues } = props;
  const { code, createdAt, plan, elevation, elevationStyle, specLevel, specLevelStyle, options } = configurationValues;

  return (
    <div css={Css.df.fdc.w100.$}>
      {/* TODO: Insert Lot Map */}

      {/* Configuration Details */}
      <div css={Css.pxPx(8 * 12).pt4.mb8.$}>
        {/* Header with bottom border */}
        <div css={Css.pb2.bb.bGray300.mb5.$}>
          <p css={Css.xl3Em.$}>Your Lot Summary</p>
        </div>

        {/* Configuration Selections */}
        <div css={Css.df.fdc.gap3.$}>
          {/* Success (if code exist) */}
          {code && createdAt && (
            <div css={Css.bgColor("#EBEAE4").br4.p4.df.aic.jcc.fdc.gap2.$}>
              <p css={Css.xl3Em.$}>You're All Set!</p>
              <div css={Css.df.gap3.$}>
                <p>
                  Reserved: <strong>{dateFormatter(createdAt, "MMMM d, y")}</strong>
                </p>
                <p>
                  Code: <strong>{code}</strong>
                </p>
              </div>
              <p css={Css.sm.tc.gray800.$}>
                Congratulations! You've just taken the first step toward your new home. Your selections have been saved
                and your spot in the queue reserved. A Homebound representative will be in touch with you shortly to
                review your options and estimated timeline. In the meantime, you can use the code above to refer back to
                your home at any time.
              </p>
            </div>
          )}
          {/* Architecture */}
          <div css={Css.df.fdc.gap2.$}>
            <p css={Css.xl2Em.$}>Architecture</p>

            {/* TODO: This should be a carousel */}
            <div>
              <img
                css={Css.w100.objectCover.hPx(480).bshBasic.br4.$}
                src={elevationStyle?.exteriorImages[0].image ?? undefined}
                alt={plan?.name}
              />
              {/* TODO: Add plan name overlay on image */}
              {/* Disclaimer */}
              <p css={Css.gray600.tiny.tr.mt1.mr1.$}>
                <em>
                  Renderings are for representational purposes only. Layout, specifications, and finishes may vary or
                  change.
                </em>
              </p>
            </div>

            {/* Details */}
            <div css={Css.df.aifs.jcsb.gap4.$}>
              <Detail label="Bedrooms" icon="bed" value={plan?.bedrooms} />
              <Detail label="Bathrooms" icon="faucet" value={plan?.bathrooms} />
              <Detail label="Square Feet" icon="sqft" value={plan?.sqft} />
              <Detail label="Exterior Style" value={elevation?.name} />
              <Detail label="Exterior Scheme" value={elevationStyle?.name} />
            </div>
          </div>

          {/* Interiors */}
          <div css={Css.df.fdc.gap2.$}>
            <p css={Css.xl2Em.$}>Interior</p>

            <div>
              <img
                css={Css.w100.objectCover.hPx(480).bshBasic.br4.$}
                src={specLevelStyle?.interiorImages[0].image ?? undefined}
                alt={specLevel?.name}
              />
              {/* Disclaimer */}
              <p css={Css.gray600.tiny.tr.mtPx(4).$}>
                <em>
                  Renderings are for representational purposes only. Layout, specifications, and finishes may vary based
                  on selections.
                </em>
              </p>
            </div>

            {/* Details */}
            <div css={Css.df.aifs.jcfe.gap4.$}>
              <Detail label="Finish Level" value={specLevel?.name} />
              <Detail label="Interior Scheme" value={specLevelStyle?.name} />
            </div>
          </div>

          {/* Options */}
          {options && options.length > 0 && (
            <div>
              <p css={Css.xl2Em.mb1.$}>Options</p>
              <p css={Css.sm.mb4.$}>
                Each of these options adds an additional cost to the base price, or may add square footage. This is an
                estimated price and options will need to be confirmed by a sales rep.{" "}
              </p>

              <div css={Css.df.fdc.gap3.$}>
                {options.map((option) => (
                  <div css={Css.df.fdr.gap4.aifs.jcsb.$} key={option.name}>
                    <p css={Css.xlEm.maxw50.$}>{option.name}</p>

                    {/* Details */}
                    <div css={Css.df.gap3.jcfe.maxw50.add("flexWrap", "wrap").$}>
                      {isValidOptionDetail(option.baseSqft) && (
                        <Detail label="Added Square FT" icon="sqft" value={option.baseSqft} />
                      )}
                      {isValidOptionDetail(option.bedrooms) && (
                        <Detail label="Added Bedrooms" icon="bed" value={option.bedrooms} />
                      )}
                      {isValidOptionDetail(option.bathrooms) && (
                        <Detail label="Added Bathrooms" icon="faucet" value={option.bathrooms} />
                      )}
                      {isValidOptionDetail(option.garageParking) && (
                        <Detail label="Added Parking" icon="square-parking" value={option.garageParking} />
                      )}
                      <Detail
                        label="Price"
                        icon="square-dollar"
                        value={
                          option.priceInCents !== 0 ? (
                            currencyFormatter(option.priceInCents)
                          ) : (
                            <span>
                              TBD<sup>[2]</sup>
                            </span>
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Given a configuration query response, format it to match the configuration context value
 */
function formatConfiguration(
  configuration: ConfigurationQuery["configuration"],
): Omit<ConfigurationValues, "priceInCents" | "canReserve"> {
  const {
    code,
    createdAt,
    plan,
    elevation,
    elevationStyle,
    specLevel,
    specLevelStyle,
    toOptions: _options,
  } = configuration;

  // Format options
  const options: OptionFragment[] = _options.map(({ option }) => ({
    id: option.id,
    name: option.name,
    description: option.description,
    location: option.location,
    group: option.group,
    baseSqft: option.baseSqft,
    bedrooms: option.bedrooms,
    bathrooms: option.bathrooms,
    garageParking: option.garageParking,
    floor: option.floor,
    // Find the option price based on the chosen spec level
    get priceInCents() {
      return option.toSpecLevels.find((tpl) => tpl.specLevel.id === specLevel.id)?.priceInCents ?? 0;
    },
    // This is only included to resolve types
    optionToOptions: [],
  }));

  return {
    code,
    createdAt: new Date(createdAt).toISOString(),
    plan,
    elevation,
    elevationStyle,
    specLevel,
    specLevelStyle,
    options,
  };
}

/** Given configuration values, generate <Summary /> props */
export function configurationToSummaryProps(configuration: ConfigurationValues): SummaryProps {
  const {
    // Main Features
    plan,
    elevation,
    elevationStyle,
    specLevel,
    specLevelStyle,
    mainFeaturesPriceInCents,
    // Options
    options = [],
    totalPriceInCents,
  } = configuration;

  // Bail if we are missing any configuration information. Most likely due to network request loading.
  if (!plan || !elevation || !elevationStyle || !specLevel || !specLevelStyle)
    return { groups: [], totalComponents: [] };

  // Calculate main features cost per sqft
  const mainFeaturesSqft = plan.baseSqft;
  const mainFeaturesCostPerSqft = (mainFeaturesPriceInCents ?? 0) / mainFeaturesSqft; // (e.g. 10_000_00 / 3400 = 300)

  // Group options by locations as each location will be a group
  const optionsByLocation = groupBy(options, (option) => option.location);

  // Calculate total cost per sqft and savings
  const optionSqft = options.reduce((acc, option) => acc + option.baseSqft, 0);
  const totalSqft = mainFeaturesSqft + optionSqft;
  const totalCostPerSqft = (totalPriceInCents ?? 0) / totalSqft; // (e.g. 10_000_00 / 3400 = 300)

  return {
    groups: [
      {
        title: "Main Features",
        icon: <Icon name="home" />,
        lineItems: [
          {
            title: plan.name,
            subTitle: "Plan",
            price: currencyFormatter(plan.priceInCents),
            subPrice: `(${currencyFormatter(mainFeaturesCostPerSqft)}/sqft)`,
          },
          {
            title: elevation.name,
            subTitle: "Style",
            price: elevation.priceInCents !== 0 ? currencyFormatter(elevation.priceInCents) : "Included",
          },
          {
            title: elevationStyle.name,
            subTitle: "Exterior Scheme",
            price: elevationStyle.priceInCents !== 0 ? currencyFormatter(elevationStyle.priceInCents) : "Included",
          },
          {
            title: specLevel.name,
            subTitle: "Finish Level",
            price: specLevel.priceInCents !== 0 ? currencyFormatter(specLevel.priceInCents) : "Included",
          },
          {
            title: specLevelStyle.name,
            subTitle: "Interior Scheme",
            price: specLevelStyle.priceInCents !== 0 ? currencyFormatter(specLevelStyle.priceInCents) : "Included",
          },
        ],
      },
      ...Object.entries(optionsByLocation).map(([location, options]) => ({
        title: location + " Options",
        icon: getLocationIcon(location),
        lineItems: options.map((option) => ({
          title: option.name,
          price:
            option.priceInCents !== 0 ? (
              currencyFormatter(option.priceInCents)
            ) : (
              <span>
                TBD<sup>[2]</sup>
              </span>
            ),
        })),
      })),
    ],
    totalComponents: [
      <div css={Css.df.aic.jcsb.$} key="Total">
        <p css={Css.xlEm.$}>
          Total<sup>[1]</sup>
        </p>
        {/* only show pricing for full access users */}
        <p css={Css.xlEm.$}>{isFullAccessUser() ? currencyFormatter(totalPriceInCents) : "Contact Homebound"}</p>
      </div>,
      // Cost per sqft
      <div css={Css.df.aic.jcfe.$} key="CostPerSqft">
        <div css={Css.br4.bgGray900.px1.pyPx(4).$}>
          <p css={Css.xsEm.white.$}>{`${currencyFormatter(totalCostPerSqft)} / SQFT`}</p>
        </div>
      </div>,
    ],
  };
}

/**
 * Mapping between option location to option icon.
 * Will log an error when an option location is not found.
 */
function getLocationIcon(location: string) {
  switch (location.toLowerCase()) {
    case "backyard":
      return <Icon name="tree" />;
    case "basement":
      return <Icon name="basement" />;
    case "bedroom":
      return <Icon name="bed" />;
    case "built-ins":
      return <Icon name="shelves" />;
    case "energy & safety":
      return <Icon name="thermometer" />;
    case "garage":
      return <Icon name="square-parking" />;
    case "kitchen":
      return <Icon name="cutlery" />;
    case "laundry room":
      return <Icon name="faucet" />;
    case "study & workspace":
      return <Icon name="office" />;
    case "whole home":
      return <Icon name="home" />;
    default:
      console.error("No location icon found for", location);
      return null;
  }
}
