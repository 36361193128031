import { ReactNode } from "react";
import { Css } from "~generated/css";

interface Props {
  children?: ReactNode;
}

export function Watermark({ children, ...props }: Props) {
  return (
    <div css={Css.absolute.dib.pr2.pl2.pt1.pb1.top2.left2.tc.br8.bgColor("rgba(254,254,254,.7)").$}>{children}</div>
  );
}
