import { useMachine } from "@xstate/react";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { numberFormatter } from "src/utils/formatter.utils";
import { assign, createMachine } from "xstate";
import { Icon, Map, SearchField } from "~components";
import { Css } from "~generated/css";
import {
  PropertyAddressFragment,
  PropertyFragment,
  usePropertiesLazyQuery,
  usePropertyLazyQuery,
} from "~generated/graphql";
import { titleCase } from "~utils";

// Creating custom fragment types since we want to map `dpid` to `id`, however
// Storybook does not allow alias in mocks.
type Property = Omit<PropertyFragment, "dpid"> & { id: PropertyFragment["dpid"] };
type PropertyAddress = Omit<PropertyAddressFragment, "dpid"> & { id: PropertyAddressFragment["dpid"] };

/** Lot step "I have a lot" tab right pane component */
export function Boyl() {
  /** Queries **/
  const [getProperties] = usePropertiesLazyQuery();
  const [getProperty] = usePropertyLazyQuery();

  return <BoylBase getProperties={getProperties} getProperty={getProperty} />;
}

type BoylBaseProps = {
  getProperties: ReturnType<typeof usePropertiesLazyQuery>[0];
  getProperty: ReturnType<typeof usePropertyLazyQuery>[0];
};

/** SearchField State Machine **/
// This defines data that will be stored in the state machine.
type SearchFieldContext = {
  address: string;
  properties: PropertyAddress[] | null;
  property: Property | null;
};

// This defines the possible states that the state machine can be in.
enum SearchFieldState {
  Idle = "idle",
  OnChange = "onChange",
  Fetching = "fetching",
  OnSelect = "onSelect",
}

// This defines the state machine for the SearchField itself
const searchFieldMachine = createMachine({
  // https://xstate.js.org/docs/guides/actions.html#actions
  predictableActionArguments: true,
  // Defines the context type
  schema: { context: {} as SearchFieldContext },
  // Initial state
  initial: SearchFieldState.Idle,
  // States
  states: {
    [SearchFieldState.Idle]: {
      // These are the possible transitions that can happen from this state
      // So when in the "Idle" state, we can only transition to the "onChange" state or the "onSelect" state.
      // All other state transitions will fail.
      // See more https://xstate.js.org/docs/guides/transitions.html
      on: {
        [SearchFieldState.OnChange]: SearchFieldState.OnChange,
        [SearchFieldState.OnSelect]: SearchFieldState.OnSelect,
      },
    },
    [SearchFieldState.OnChange]: {
      // This defined an action which will happen upon "entry" to this state.
      // All actions are defined in the component via the `actions` key.
      // See more https://xstate.js.org/docs/guides/actions.html
      entry: "updateAddress",
      // This is a little trick to reset the `after` timer. So when the user
      // types, it will reset the 200ms timer to wait before fetching.
      on: {
        [SearchFieldState.OnChange]: SearchFieldState.OnChange,
      },
      // This says, "after 200ms, transition to the `fetching` state only if the
      // context address value is not empty."
      after: {
        200: {
          // This condition, is called a transition "guard". The implementation
          // of this function is defined in the "guards" key.
          // See more https://xstate.js.org/docs/guides/guards.html
          cond: "isAddressNotEmpty",
          target: SearchFieldState.Fetching,
        },
      },
    },
    [SearchFieldState.Fetching]: {
      // In XState, there are two types of side effects which can happen upon
      // a transition, either an "action" which is a "fire-and-forget" which is
      // great to update the context or a "service" which is a promise that can
      // be awaited.
      invoke: {
        // In this case, we are fetching properties using the context address.
        src: "fetchProperties",
        // Once the service has resolved, we will transition to the "idle" state.
        // And call another action to update the context properties.
        onDone: {
          actions: "updateProperties",
          target: SearchFieldState.Idle,
        },
        onError: {
          target: SearchFieldState.Idle,
        },
      },
      // Note that at anytime this state can transition to the "onChange" state
      // and the awaited "service" will be cancelled.
      on: {
        [SearchFieldState.OnChange]: SearchFieldState.OnChange,
      },
    },
    [SearchFieldState.OnSelect]: {
      invoke: {
        src: "fetchProperty",
        onDone: {
          actions: "updateProperty",
          target: SearchFieldState.Idle,
        },
        onError: {
          target: SearchFieldState.Idle,
        },
      },
      on: {
        [SearchFieldState.OnChange]: SearchFieldState.OnChange,
      },
    },
  },
});

/**
 * Non-Apollo wrapper for Lot Step "I have a lot" tab right pane component
 */
function BoylBase(props: BoylBaseProps) {
  /** Queries **/
  const { getProperties, getProperty } = props;

  /** State Machine **/
  const [state, send] = useMachine(searchFieldMachine, {
    devTools: true,
    // Initial context
    context: {
      // The search field value
      address: "",
      // The properties matching the `address` value
      properties: null,
      // The selected property
      property: null,
    },
    actions: {
      updateAddress: assign<SearchFieldContext>({
        // @ts-expect-error it's still unclear how typing is propagated
        address: (_, event) => event.data,
        properties: null, // Clear properties when address changes
      }),
      // @ts-expect-error it's still unclear how typing is propagated
      updateProperties: assign<SearchFieldContext>({ properties: (_, event) => event.data }),
      updateProperty: assign<SearchFieldContext>({
        // @ts-expect-error it's still unclear how typing is propagated
        address: (_, event) => formatPropertyAddress(event.data),
        // @ts-expect-error it's still unclear how typing is propagated
        property: (_, event) => event.data,
        properties: null, // Clear properties when property is selected
      }),
    },
    guards: {
      isAddressNotEmpty: (ctx, event) => {
        return ctx.address !== "";
      },
    },
    services: {
      fetchProperties: async (ctx) => {
        const { address } = ctx;
        const { data } = await getProperties({ variables: { address } });
        // Mapping `dpid` -> `id` since aliasing is not supported in Storybook
        const properties = data?.properties.map(({ dpid, ...property }) => ({ ...property, id: dpid })) ?? [];

        return properties;
      },
      fetchProperty: async (ctx, event) => {
        const propertyAddress = event.data;
        const { id } = propertyAddress as PropertyAddress;
        // Query for more details about the selected property
        const { data } = await getProperty({ variables: { id } });
        // Doing this mapping since aliasing is not supported in Storybook
        const selectedProperty =
          ((data?.property && {
            ...data?.property,
            // Mapping `dpid` -> `id`
            id: data?.property.dpid,
          }) as Property) ?? null;

        return selectedProperty;
      },
    },
  });

  /** Transformers **/
  const propertyMarker = useMemo(() => {
    const property = state.context.property;
    if (!property) return undefined;

    const { id, latitude: lat, longitude: lng } = property;
    return [{ id, center: { lat, lng }, isActive: true }];
  }, [state.context.property]);

  const propertyDetails = useMemo(() => {
    const { lotSize, buildableSqft, lotFrontageFeet, lotDepthFeet } = state.context.property ?? {};

    return [
      {
        label: "Lot Size Sqft",
        value: lotSize ? numberFormatter(lotSize) : "-",
      },
      {
        label: "Buildable Sqft",
        value: buildableSqft ? numberFormatter(buildableSqft) : "-",
      },
      {
        label: "Lot Frontage (ft)",
        value: lotFrontageFeet ? numberFormatter(lotFrontageFeet) : "-",
      },
      {
        label: "Lot Depth (ft)",
        value: lotDepthFeet ? numberFormatter(lotDepthFeet) : "-",
      },
    ];
  }, [state.context.property]);

  /** Renderer **/
  return (
    <>
      {/* Map */}
      <div css={Css.w100.hPx(576).$}>
        <Map markers={propertyMarker} center={propertyMarker ? propertyMarker[0].center : undefined} />
      </div>

      <div css={Css.pxPx(8 * 10).pt4.$}>
        <h2 css={Css.xl3Em.mb3.$}>Your Lot's Address</h2>

        {/* Address Search Container */}
        <div css={Css.bgTaupe.p4.$}>
          {/* Search Input */}
          <div css={Css.df.aic.gap3.mb3.$}>
            <Icon name="home" />

            <SearchField
              placeholder="Enter your address"
              value={state.context.address}
              onChange={(_, address) => send(SearchFieldState.OnChange, { data: address })}
              options={state.context.properties}
              onSelect={(propertyAddress) => send(SearchFieldState.OnSelect, { data: propertyAddress })}
              isLoading={state.matches(SearchFieldState.Fetching)}
            >
              {{
                option: PropertyOptionRenderer,
                noOption: () => <NoPropertyOptionRenderer address={state.context.address} />,
              }}
            </SearchField>
          </div>
          {/* Search Results Container */}
          <div css={Css.df.aic.jcsb.gap1.$}>
            {propertyDetails.map((detail) => (
              // Search Result
              <div css={Css.df.fdc.gap1.$} key={detail.label}>
                <p css={Css.xlEm.$}>{detail.value}</p>
                <p css={Css.smEm.gray.$}>{detail.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

/** Helpers **/
/** Custom SearchField option renderer */
function PropertyOptionRenderer(props: PropertyAddress) {
  const address = formatPropertyAddress(props);

  return (
    <motion.div
      css={{
        ...Css.cursorPointer.px2.py1.smEm.ttc.$,
        ...{
          borderLeftWidth: 4,
          borderLeftStyle: "solid",
          borderLeftColor: "transparent",
        },
        ":hover": Css.bgGray200.bGray900.$,
      }}
    >
      {address}
    </motion.div>
  );
}

/** Custom SearchField empty option renderer */
function NoPropertyOptionRenderer(props: { address: string }) {
  const { address } = props;
  return <p css={Css.px2.py1.smEm.$}>No properties found for address "{address}"</p>;
}

/** Simple `PropertyAddress` type to string formatter */
function formatPropertyAddress(property: PropertyAddress) {
  const { fullStreetAddress, cityName, state, zipCode } = property;
  return titleCase(`${fullStreetAddress}, ${cityName}, ${state} ${zipCode}`.toLowerCase());
}
