import { useButton } from "@react-aria/button";
import { useFocusRing } from "@react-aria/focus";
import { AriaListBoxOptions, useListBox, useOption } from "@react-aria/listbox";
import { AriaOverlayProps, DismissButton, useOverlay } from "@react-aria/overlays";
import { HiddenSelect, useSelect } from "@react-aria/select";
import { mergeProps } from "@react-aria/utils";
import { ListState } from "@react-stately/list";
import { useSelectState } from "@react-stately/select";
import { AriaSelectProps } from "@react-types/select";
import { Node } from "@react-types/shared";
import { ReactNode, RefObject, useRef } from "react";
import { Icon } from "~components";
import { Css } from "~generated/css";

type SelectFieldProps<Option> = AriaSelectProps<Option> & {
  testId?: string;
  label: string;
};

/** React-Aria and React-Stately compatible select field */
export function SelectField<Option extends object>(props: SelectFieldProps<Option>) {
  const { testId = "select-field", label } = props;
  const state = useSelectState(props);
  const triggerRef = useRef(null);
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, triggerRef);
  const { buttonProps } = useButton(triggerProps, triggerRef);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <div css={Css.df.fdc.gapPx(4).relative.$} data-testid={testId}>
      <label css={Css.tinyEm.mb1.ttu.gray800.$} {...labelProps}>
        {label}
      </label>

      <HiddenSelect state={state} triggerRef={triggerRef} label={label} name={label} />

      {/* Trigger */}
      <button
        {...mergeProps(buttonProps, focusProps)}
        ref={triggerRef}
        css={
          Css.p2.bshBasic.br4.outline0
            .add("appearance", "none")
            .bw2.bsSolid.bc("transparent")
            .if(isFocusVisible)
            .bc("#353535")
            .if(state.isOpen).bgGray100.$
        }
      >
        <div {...valueProps} css={Css.relative.$}>
          {/* TODO: Support custom trigger renderer */}
          {!state.selectedItem && "Please chose an option"}
          {state.selectedItem && (
            <>
              {state.selectedItem.rendered}
              {/* Right arrow */}
              <div aria-hidden={true} css={Css.absolute.right0.top("50%").add("transform", "translateY(-50%)").$}>
                <Icon name={state.isOpen ? "arrow-up" : "arrow-down"} />
              </div>
            </>
          )}
        </div>
      </button>

      {state.isOpen && (
        <Popover isOpen={state.isOpen} onClose={state.close}>
          <ListBox {...menuProps} state={state} />
        </Popover>
      )}
    </div>
  );
}

type PopoverProps = AriaOverlayProps & {
  popoverRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
};

function Popover(props: PopoverProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { popoverRef = ref, isOpen, onClose, children } = props;
  // Handle interacting outside the dialog or pressing the `Escape` key to close the modal.
  const { overlayProps } = useOverlay({ isOpen, onClose, shouldCloseOnBlur: true, isDismissable: true }, popoverRef);

  return (
    <>
      <div {...overlayProps} ref={ref} css={Css.absolute.z1.br4.mt2.top("100%").w100.bshBasic.bgWhite.$}>
        {children}
      </div>
      <DismissButton onDismiss={onClose} />
    </>
  );
}

type ListBoxProps<Option> = AriaListBoxOptions<Option> & {
  listBoxRef?: RefObject<HTMLUListElement>;
  state: ListState<Option>;
};

function ListBox<Option extends object>(props: ListBoxProps<Option>) {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    // TODO: Add styles to this
    <ul css={Css.maxhPx(300).overflowAuto.listReset.m0.p0.outline0.$} {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
}

type OptionsProps<Option> = {
  item: Node<Option>;
  state: ListState<Option>;
};

function Option<Option extends object>(props: OptionsProps<Option>) {
  const ref = useRef<HTMLLIElement>(null);
  const { item, state } = props;
  const { optionProps, isFocused } = useOption({ key: item.key }, state, ref);

  return (
    <li
      {...optionProps}
      css={Css.listReset.p2.outline0.cursorPointer.bsSolid.bw2.bc("transparent").if(isFocused).bc("#353535").$}
      ref={ref}
    >
      {item.rendered}
    </li>
  );
}
