import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { Css } from "~generated/css";

const steps = [
  { label: "Lot", path: "/lot" },
  { label: "Architecture", path: "/architecture" },
  { label: "Interior", path: "/interior" },
  { label: "Options", path: "/options" },
];

const MotionLink = motion(Link);

export function Nav() {
  const { pathname } = useLocation();

  return (
    <motion.nav css={Css.mx2.mt2.py1.px2.bgGray200.br24.df.jcse.$}>
      {steps.map(({ label, path }) => {
        const isActive = path === pathname;

        return (
          <MotionLink
            to={path}
            key={path}
            layout
            animate={isActive ? Css.bgGray900.white.$ : ""}
            transition={{ duration: 0.2 }}
            css={Css.noUnderline.xsEm.gray900.ttu.py1.px2.br24.$}
          >
            {label}
          </MotionLink>
        );
      })}
    </motion.nav>
  );
}
