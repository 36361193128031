/**
 * This file includes all data helpers and mock data used for tests and
 * Storybook stories.
 */
import {
  newElevation,
  newElevationStyle,
  newExteriorImage,
  newFloor,
  newInteriorImage,
  newOption,
  newOptionToOption,
  newPlan,
  newSpecLevel,
  newSpecLevelStyle,
} from "~generated/graphql";

/**
 * Data
 * ----
 *
 * Data to be used in stories and tests.
 */
/** Elevation Styles Images **/
const elevationStyleImage = generateImage({ width: 167, height: 50 });

/** Spec Level Styles Images **/
const specLevelStyleImage = generateImage({ width: 167, height: 50 });

/** Exterior Images **/
const exteriorImage = newExteriorImage({
  image: generateImage({ width: 970, height: 576 }),
  // Ignores
  elevationStyle: undefined,
});

/** Interior Images **/
const interiorImageKitchen = newInteriorImage({
  name: "Kitchen",
  image: generateImage({ width: 970, height: 576 }),
});
const interiorImageDiningRoom = newInteriorImage({
  name: "Dining Room",
  image: generateImage({ width: 970, height: 576 }),
});

/**  Floors */
const floor0 = newFloor({
  name: "0",
  image: generateImage({ width: 700, height: 452 }),
});
const floor1 = newFloor({
  name: "1",
  image: generateImage({ width: 700, height: 452 }),
});
const floor2 = newFloor({
  name: "2",
  image: generateImage({ width: 700, height: 452 }),
});

/** Elevation Styles **/
const includedElevationStyle = newElevationStyle({
  name: "Light",
  priceInCents: 0,
  image: elevationStyleImage,
  exteriorImages: [exteriorImage],
  // Ignores
  elevation: undefined,
});
const paidElevationStyle = newElevationStyle({
  name: "Moody",
  priceInCents: 5_000_00,
  image: elevationStyleImage,
  exteriorImages: [exteriorImage],
  // Ignores
  elevation: undefined,
});

/** Spec Level Styles **/
const includedSpecLevelStyle = newSpecLevelStyle({
  name: "Transitional",
  priceInCents: 0,
  image: specLevelStyleImage,
  interiorImages: [interiorImageKitchen, interiorImageDiningRoom],
  // Ignores
  specLevel: undefined,
});
const paidSpecLevelStyle = newSpecLevelStyle({
  name: "Contemporary",
  priceInCents: 5_000_00,
  image: specLevelStyleImage,
  interiorImages: [interiorImageKitchen, interiorImageDiningRoom],
  // Ignores
  specLevel: undefined,
});

/** Options **/
// We are exporting this option after `option2` has been declared to set an
// option conflict between them.
const _option1 = newOption({
  // Core
  id: "o:1",
  name: "Option 1",
  description: "Option 1 Description",
  location: "Kitchen",
  group: "Interior",
  image: generateImage({ width: 970, height: 576 }),
  priceInCents: 1_000_00,

  // Details
  bedrooms: "1",
  bathrooms: "1",
  baseSqft: 100,
  sqft: "100",
  garageParking: "1",

  // Floor
  floor: floor0,
});

export const option2 = newOption({
  // Core
  id: "o:2",
  name: "Option 2",
  description: "Option 2 Description",
  location: "Kitchen",
  group: "Interior",
  image: generateImage({ width: 970, height: 576 }),
  priceInCents: 2_000_00,

  // Details
  bedrooms: "2",
  bathrooms: "2",
  baseSqft: 200,
  sqft: "200",
  garageParking: "2",

  // Floor
  floor: floor1,

  // Conflicts
  optionToOptions: [newOptionToOption({ conflictOption: _option1 })],
});

// Exporting `option1` after `option2` has been declared to set an option
// conflict between them.
_option1.optionToOptions = [newOptionToOption({ conflictOption: option2 })];
export const option1 = _option1;

export const option3 = newOption({
  // Core
  id: "o:3",
  name: "Option 3",
  description: "Option 3 Description",
  location: "Energy & Safety",
  group: "Whole Home",
  image: generateImage({ width: 970, height: 576 }),
  priceInCents: 0, // To show TBD price

  // Details
  bedrooms: "3",
  bathrooms: "3",
  baseSqft: 300,
  sqft: "300",
  garageParking: "3",

  // Floor
  floor: floor1,
});

export const option4 = newOption({
  // Core
  id: "o:4",
  name: "Option 4",
  description: "Option 4 Description",
  location: "Backyard",
  group: "Exterior",
  image: generateImage({ width: 970, height: 576 }),
  priceInCents: 4_000_00,

  // Details
  bedrooms: "4",
  bathrooms: "4",
  baseSqft: 400,
  sqft: "400",
  garageParking: "4",

  // Floor
  floor: floor1,
});

/** Spec Levels **/
export const includedSpecLevel = newSpecLevel({
  name: "Base",
  priceInCents: 0,
  // SpecLevel Styles
  styles: [includedSpecLevelStyle, paidSpecLevelStyle],
  // Ignores
  elevation: undefined,
});
export const paidSpecLevel = newSpecLevel({
  name: "Essential",
  priceInCents: 10_000_00,
  // SpecLevel Styles
  styles: [includedSpecLevelStyle, paidSpecLevelStyle],
  // Ignores
  elevation: undefined,
});

/** Elevations **/
const includedElevation = newElevation({
  name: "Mountain Contemporary",
  priceInCents: 0,
  // Elevation Styles
  styles: [includedElevationStyle, paidElevationStyle],
  // Spec Levels
  specLevels: [includedSpecLevel, paidSpecLevel],
  // Ignores
  plan: undefined,
});
const paidElevation = newElevation({
  name: "Classic Craftsman",
  priceInCents: 10_000_00,
  // Elevation Styles
  styles: [includedElevationStyle, paidElevationStyle],
  // Spec Levels
  specLevels: [includedSpecLevel, paidSpecLevel],
  // Ignores
  plan: undefined,
});

/** Plans **/
export const plan0 = newPlan({
  // Core
  id: "p:0",
  airtableId: "p:0",
  name: "Plan 0",
  description: "Plan 0 description",
  priceInCents: 500_000_00,
  // Details
  bedrooms: "3 - 4",
  bathrooms: "2.5 - 3.5",
  sqft: "2,000+",
  baseSqft: 2000,
  garageParking: "2 - 3",
  // Elevations
  elevations: [includedElevation, paidElevation],
  // Floors
  floors: [floor0, floor1, floor2],
});
export const plan1 = newPlan({
  // Core
  id: "p:1",
  airtableId: "p:1",
  name: "Plan 1",
  description: "Plan 1 description",
  priceInCents: 1_000_000_00,
  // Details
  bedrooms: "3 - 4",
  bathrooms: "2.5 - 3.5",
  sqft: "2,000+",
  garageParking: "2 - 3",
  // Elevations
  elevations: [includedElevation, paidElevation],
  // Floors
  floors: [floor0, floor1, floor2],
});

/**
 * Dates
 * -----
 *
 * Dates which are used in stories and in tests
 */
export const date1 = new Date("2022-01-01T00:00:00.000Z");

/** Data Helpers **/
type GenerateImageProps = {
  /** @defaults 300 */
  height?: number;
  /** @defaults 300 */
  width?: number;
  /**
   * Must be hexadecimal without the leading `#`.
   * @defaults "F7F5F5"
   */
  backgroundColor?: string;
  /** @defaults "noto" */
  font?: "noto" | "bebas" | "lobster" | "museo";
  /**
   * Must be hexadecimal without the leading `#`.
   * @defaults "000000"
   */
  fontColor?: string;
  /** @defaults "" */
  text?: string;
};

/**
 * Helper to generate an image on demand using the fakeimg.pl service.
 *
 * Ref: https://fakeimg.pl/
 */
export function generateImage(props?: GenerateImageProps) {
  const {
    height = 300,
    width = 300,
    backgroundColor = "F7F5F5",
    font = "noto",
    fontColor = "000000",
    text = "",
  } = props ?? {};

  // Create the URL
  const url = new URL("https://fakeimg.pl");

  // Generate the path
  url.pathname = `${width}x${height}/${backgroundColor}/${fontColor}`;

  // Generate the query string
  url.searchParams.set("retina", "1"); // Generates @2x images
  url.searchParams.set("font", font); // Set the font family
  url.searchParams.set("text", text); // Set the text for the image

  return url.toString();
}
