// A function that calls `window.location.replace` allowing us to `vi.mock` it out */
export function redirectReplace(url: string): void {
  return window.location.replace(url);
}

// A function that retrieves `window.location.hostname` allowing us to `vi.mock` it out */
export function getEnv() {
  const hostname = window.location.hostname;
  if (hostname.includes("dev-homebound")) return "dev";
  if (hostname.includes("qa-homebound")) return "qa";
  if (hostname.includes("homebound")) return "prod";
  return "local";
}
