import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { NotReservedConfigurationRoutes } from "src/App";
import { Css } from "~generated/css";
import { capitalize } from "~utils";
import { Icon } from "./Icon";
import { Tab } from "./Tab";

type DualPaneLayoutProps = {
  children: {
    left: React.ReactNode;
    right: React.ReactNode;
  };
};

/**
 * Layout component which helps split the content into two columns where the
 * left columns has a Tab component
 */
export function DualPaneLayout(props: DualPaneLayoutProps) {
  const { children } = props;
  const { left, right } = children;

  const { pathname } = useLocation();
  const previousPath = useMemo(() => {
    const currentPathIndex = NotReservedConfigurationRoutes.findIndex((route) => route.path === pathname);
    const previousPathIndex = currentPathIndex - 1;

    if (previousPathIndex < 0) return undefined;
    return NotReservedConfigurationRoutes[previousPathIndex]?.path;
  }, [pathname]);

  return (
    <div css={Css.maxwPx(1440).mx("auto").fg1.df.gap7.pl8.bgColor("#FBFBFB").$}>
      {/* Left Pane */}
      <div css={Css.pt4.add("flexBasis", "350px").mwPx(350).$}>
        {/* Back Button */}
        <div css={Css.mb4.mlPx(-32).h3.$}>
          {previousPath && (
            <Link to={previousPath} css={Css.df.gap1.aic.xs.gray900.w("fit-content").important.$}>
              <Icon name="arrow-back" />
              Back
            </Link>
          )}
        </div>

        <div>
          <Tab
            options={NotReservedConfigurationRoutes.map((r) => capitalize(r.path?.replace("/", "") ?? ""))}
            selected={capitalize(pathname.replace("/", ""))}
            xss={Css.mb5.$}
          />
          {left}
        </div>
      </div>
      {/* Right Pane */}
      <div css={Css.fg1.df.bgGray100.$}>{right}</div>
    </div>
  );
}
