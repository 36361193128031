/**
 * Given an option detail (sqft, bedrooms, etc.), determine if it's a valid
 * option details which means that it does not contain 0 or "0"
 *
 *
 * determine if it includes a string other than "0".
 * "0" denotes that this option detail is not affecting the option.
 */
export function isValidOptionDetail(detail?: string | number) {
  if (typeof detail === "number") return detail !== 0;
  return detail !== "0";
}
