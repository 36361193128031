import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Watermark } from "src/components/Watermark";
import { DualPaneLayout, Icon, NextCTA, RadioField, SelectionCard } from "~components";
import { ConfigurationActions, ConfigurationValues, useConfiguration } from "~contexts";
import { Css } from "~generated/css";
import { SpecLevelFragment, useSpecLevelsQuery } from "~generated/graphql";

type InteriorConfigurationValues = Pick<ConfigurationValues, "specLevel" | "specLevelStyle">;
type InteriorConfigurationActions = Pick<ConfigurationActions, "setSpecLevel" | "setSpecLevelStyle">;

export function InteriorStep() {
  const navigate = useNavigate();

  const { elevation, specLevel, specLevelStyle, setSpecLevel, setSpecLevelStyle } = useConfiguration();

  const { data } = useSpecLevelsQuery({ variables: { elevationId: elevation?.id ?? "" }, skip: !elevation });
  const specLevels = useMemo(() => data?.specLevels as SpecLevelFragment[], [data]);

  // Redirect to `/architecture` when `elevation` is missing
  useEffect(() => {
    if (!elevation) navigate("/architecture");
  }, [elevation, navigate]);

  // Don't render the page if we are missing `elevation` or `specLevel`.
  // `elevation` could be missing due to skipping `/architecture` step
  // `specLevel` could be missing due to waiting on the network response.
  // TODO: Should show an loading animation
  if (!elevation || !specLevels) return null;
  return (
    <DualPaneLayout>
      {{
        left: (
          <LeftPane
            specLevels={specLevels}
            configurationValues={{ specLevel, specLevelStyle }}
            configurationActions={{ setSpecLevel, setSpecLevelStyle }}
          />
        ),
        right: <RightPane specLevels={specLevels} configurationValues={{ specLevel, specLevelStyle }} />,
      }}
    </DualPaneLayout>
  );
}

type LeftPaneProps = {
  specLevels?: SpecLevelFragment[];
  configurationValues: InteriorConfigurationValues;
  configurationActions: InteriorConfigurationActions;
};
function LeftPane(props: LeftPaneProps) {
  const { specLevels, configurationValues, configurationActions } = props;
  const { specLevel, specLevelStyle } = configurationValues;
  const { setSpecLevel, setSpecLevelStyle } = configurationActions;

  // When there is no specLevel selected, select the first specLevel.
  // This happens during a new configuration.
  useEffect(() => {
    if (!specLevel && specLevels) setSpecLevel(specLevels[0]);
  }, [specLevel, specLevels, setSpecLevel]);

  // Only render the left pane when a spec and a style can selected.
  if (!specLevels || !specLevel || !specLevelStyle) return null;
  return (
    <div css={Css.df.fdc.gap3.$}>
      <RadioField label="Finish Level" options={specLevels} value={specLevel.id} onChange={setSpecLevel}>
        {(spec, context) => {
          const { name, priceInCents } = spec;
          const { selected } = context;
          return (
            <SelectionCard
              name={name}
              priceInCents={priceInCents}
              image={`/spec-${name.toLowerCase()}.png`}
              selected={selected}
            />
          );
        }}
      </RadioField>

      <RadioField
        label="Interior Scheme"
        options={specLevel?.styles}
        value={specLevelStyle?.id}
        onChange={setSpecLevelStyle}
      >
        {(spec, context) => {
          const { name, priceInCents, interiorImages } = spec;
          const { selected } = context;
          // Show the first image the interior images
          const image = interiorImages[0].image;
          return (
            <SelectionCard name={name} priceInCents={priceInCents} image={image ?? undefined} selected={selected} />
          );
        }}
      </RadioField>

      {/* Personalization information */}
      <div css={Css.br4.bgGray200.p2.df.fdc.gap1.$}>
        <div css={Css.df.gap1.$}>
          <div>
            <Icon name="flashlight" />
          </div>
          <p css={Css.gray900.sm.$}>More personalization available once you connect with the Homebound team.</p>
        </div>
      </div>
    </div>
  );
}

type RightPaneProps = {
  specLevels?: SpecLevelFragment[];
  configurationValues: InteriorConfigurationValues;
};
function RightPane(props: RightPaneProps) {
  const { configurationValues } = props;
  const { specLevelStyle } = configurationValues;

  // Stores the selected interior image index for the selected spec level style.
  // Default to the first interior image for the spec level style
  const [interiorImageIndex, setInteriorImageIndex] = useState<number>(0);

  // When the spec level style changes, make sure that there is an interior image
  // available at the current `interiorImageIndex`. If not, set to 0.
  useEffect(() => {
    // Skip if there is no specLevelStyle since we can't access it's interior images
    if (!specLevelStyle) return;

    // When there is a specLevelStyle, make sure there is an interior image available
    if (specLevelStyle.interiorImages.length <= interiorImageIndex) {
      setInteriorImageIndex(0);
    }
  }, [specLevelStyle, interiorImageIndex]);

  const currentInteriorImage = useMemo(
    () => specLevelStyle?.interiorImages[interiorImageIndex],
    [specLevelStyle, interiorImageIndex],
  );

  return (
    <div css={Css.df.fdc.w100.$}>
      {/* TODO: Add Carousel component */}
      <div css={Css.w100.relative.hPx(576).$}>
        <Watermark>Plan 1 Shown</Watermark>
        <img
          css={Css.w100.objectCover.hPx(576).$}
          src={currentInteriorImage?.image ?? undefined}
          alt={currentInteriorImage?.name}
        />
      </div>

      {/* Disclaimer */}
      <p css={Css.gray600.tiny.tr.mt1.mr1.$}>
        <em>
          Renderings are for representational purposes only. Layout, specifications, and finishes may vary or change.
        </em>
      </p>

      {/* Bottom Content */}
      <div css={Css.pxPx(8 * 12).pt4.$}>
        <h1 css={Css.xl3Em.mb2.$}>Explore Rooms</h1>

        {/* Room Selector */}
        <RadioField
          options={specLevelStyle?.interiorImages}
          value={currentInteriorImage?.id}
          onChange={(interiorImage) =>
            setInteriorImageIndex(specLevelStyle?.interiorImages.findIndex((ii) => ii.id === interiorImage.id) ?? 0)
          }
        >
          {(option, context) => {
            const { name, image } = option;
            const { selected } = context;
            return (
              <div>
                <div css={Css.hPx(140).wPx(140).br4.relative.overflowHidden.mb1.bshHover.$}>
                  {/* TODO: Animate this color change with Framer Motion */}
                  <div css={Css.hPx(10).absolute.top0.left0.right0.if(selected).bgYellow500.$} />
                  <img css={Css.h100.w100.objectCover.$} src={image ?? undefined} alt={name} />
                </div>
                <p css={Css.baseEm.$}>{name}</p>
              </div>
            );
          }}
        </RadioField>
      </div>
      <NextCTA to="/options" heading="Next: Choose Your Options" subheading="Select options by room." />
    </div>
  );
}
