import { useRef } from "react";
import { Icon, IconProps, Tooltip, TooltipProps } from "~components";
import { Css, Margin, Xss } from "~generated/css";

type ButtonXss = Xss<Margin>;
export type ButtonProps = {
  xss?: ButtonXss;
  iconLeft?: IconProps["name"];
  children: string;
  /** Optional onClick since when used with forms, onClick is not required */
  onClick?: () => void;
  variant?: "primary" | "secondary";
  /**
   * The `disabled` prop can accepted boolean which will change how it looks and
   * stop any click events.
   *
   * The `disabled` prop can also be a string which will be disable the button
   * and render a tooltip on hover. This is known as the disabled reason.
   */
  disabled?: boolean | string;
  /** Tooltip placement defaults to "right" but can accept a custom placement */
  tooltipPlacement?: TooltipProps["placement"];
};

export function Button(props: ButtonProps) {
  const { iconLeft, children, onClick, variant = "primary", disabled, tooltipPlacement = "right", xss } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const showDisabledTooltip = typeof disabled === "string";

  return (
    <button
      onClick={onClick}
      css={[
        styles.base,
        variant === "primary" ? styles.primary : styles.secondary,
        disabled ? styles.disabled : null,
        xss,
      ]}
      disabled={!!disabled}
      ref={buttonRef}
    >
      {iconLeft && <Icon name={iconLeft} />}
      {children}

      {/*  */}
      {showDisabledTooltip && (
        <Tooltip triggerRef={buttonRef} placement={tooltipPlacement}>
          {disabled}
        </Tooltip>
      )}
    </button>
  );
}

const styles = {
  base: Css.py1.px2.br4.df.aic.jcc.gap1.bsSolid.bw1.relative.$,

  primary: Css.bgColor("#353535").white.$,
  secondary: Css.bgColor("#FEFEFE").gray900.bGray300.$,

  disabled: Css.bgGray400.bGray400.cursorNotAllowed.$,
};
