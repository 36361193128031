import { format } from "date-fns";
import { isFullAccessUser } from "~contexts";

/** Number Formatters **/
export const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
}).format;

/** Currency Formatters **/
const _currentFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;
export const currencyFormatter = (priceInCents?: number) => {
  if (!isFullAccessUser()) {
    return "-";
  }
  return _currentFormatter((priceInCents ?? 0) / 100);
};

const _currentFormatterWithSign = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  signDisplay: "exceptZero",
}).format;
export const currencyFormatterWithSign = (priceInCents?: number) => {
  if (!isFullAccessUser()) {
    return "-";
  }
  return _currentFormatterWithSign((priceInCents ?? 0) / 100);
};

/** Date Formatters **/
export function dateFormatter(date: string | Date, formatTemplate: string): string {
  if (typeof date === "string") return format(new Date(date), formatTemplate);
  return format(date, formatTemplate);
}
