// FIXME: Why is this required. Can we remove this?

// Used to break structural typing between Date and DateOnly, because we don't
// want non-DateOnly Date's being passed to GraphQL queries/mutations as-is.
//
// (Because when they're put on the wire, the Date.toJSON method will include
import { FieldPolicy } from "@apollo/client";
import { parseISO } from "date-fns";

// timestamp information and the GraphQL server will reject it as an invalid type.
// eslint-disable-next-line
const NotDate = Symbol();

/**
 * A calendar day, i.e. a date without any time or time zone.
 *
 * Constructed by calling `new DateOnly(anExistingDate)`.
 */
export class DateOnly extends Date {
  constructor(public date: Date) {
    super(date.getTime());
  }

  toString() {
    return this.toISOString().split("T")[0];
  }

  toJSON() {
    return this.toString();
  }

  [NotDate]: undefined;
}

/** An alias for Date that specifically has time (unlike DateOnly). */
export type DateTime = Date;

// eslint-disable-next-line
function isNullOrUndefined(param: any | null | undefined): param is null | undefined {
  return param === null || param === undefined;
}

export const dateTimeTypePolicy: FieldPolicy<DateTime, string | Date, string> = {
  merge: (_, incoming) => {
    if (isNullOrUndefined(incoming)) {
      return incoming;
    } else if (incoming instanceof Date) {
      return incoming;
    } else {
      return parseISO(incoming as string);
    }
  },
};
