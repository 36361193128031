import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
// TODO: Do we need this?
import { OverlayProvider } from "@react-aria/overlays";
import { AnimatePresence } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssReset } from "~components";
import { ConfigurationProvider } from "~contexts";
import App from "./App";
import { getEnv } from "./utils/window";

const client = new ApolloClient({ uri: getAPIUrl(), cache: new InMemoryCache() });
if (getEnv() !== "local") startDatadog();

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  // Providers
  <ApolloProvider client={client}>
    <React.StrictMode>
      {/* Moved AnimatePresence here from Animated Route component as it was causing white screen of death in Firefox */}
      <AnimatePresence mode="wait">
        <BrowserRouter>
          <OverlayProvider>
            <ConfigurationProvider>
              <CssReset />
              <App />
            </ConfigurationProvider>
          </OverlayProvider>
        </BrowserRouter>
      </AnimatePresence>
    </React.StrictMode>
  </ApolloProvider>,
);

/** Helpers **/
function getAPIUrl() {
  const stage = getEnv();
  // @ts-expect-error TODO: Fix this typing issue via vite global variables.
  if (stage === "local") return VITE_GRAPHQL_SERVICE ?? "http://0.0.0.0:4004/graphql";
  // PR Previews and development
  else if (stage === "dev" || stage === "qa") return "https://checkout-graphql.dev-homebound.com/graphql";
  // Production
  return "https://checkout-graphql.homebound.com/graphql";
}

function startDatadog() {
  // Configure Datadog RUM
  // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    applicationId: "cde0c14d-96a1-48c9-9a83-95290a644c30",
    clientToken: "pubac7095f39e5ea93aa0ac34224b0ed3c4",
    site: "datadoghq.com",
    service: "checkout-frontend",
    env: getEnv(),
    // @ts-expect-error TODO: Fix this typing issue via vite global variables.
    version: VITE_GIT_COMMIT ?? "local",
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [getAPIUrl()],
  });

  // Start the Rum session.
  datadogRum.startSessionReplayRecording();
}
