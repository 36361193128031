import { Item } from "@react-stately/collections";
import { useEffect, useMemo } from "react";
import { DualPaneLayout, FloorPlanCard, Icon, NextCTA, RadioField, SelectField, SelectionCard } from "~components";
import { ConfigurationActions, ConfigurationValues, useConfiguration } from "~contexts";
import { Css } from "~generated/css";
import { PlanFragment, usePlansQuery } from "~generated/graphql";
import { currencyFormatter } from "~utils";

type ArchitectureConfigurationValues = Pick<ConfigurationValues, "plan" | "elevation" | "elevationStyle">;
type ArchitectureConfigurationActions = Pick<ConfigurationActions, "setPlan" | "setElevation" | "setElevationStyle">;

export function ArchitectureStep() {
  const { plan, elevation, elevationStyle, setPlan, setElevation, setElevationStyle } = useConfiguration();
  const { data } = usePlansQuery();
  const plans = useMemo(() => data?.plans as PlanFragment[] | undefined, [data]);

  return (
    <DualPaneLayout>
      {{
        left: (
          <LeftPane
            plans={plans}
            configurationValues={{ plan, elevation, elevationStyle }}
            configurationActions={{ setPlan, setElevation, setElevationStyle }}
          />
        ),
        right: <RightPane configurationValues={{ plan, elevation, elevationStyle }} />,
      }}
    </DualPaneLayout>
  );
}

type LeftPaneProps = {
  plans?: PlanFragment[];
  configurationValues: ArchitectureConfigurationValues;
  configurationActions: ArchitectureConfigurationActions;
};

function LeftPane(props: LeftPaneProps) {
  const { plans, configurationValues, configurationActions } = props;
  const { plan, elevation, elevationStyle } = configurationValues;
  const { setPlan, setElevation, setElevationStyle } = configurationActions;

  // Keep track of the elevation style index to show the correct elevation image
  // for unselected elevations when the palette changes.
  const elevationStyleIndex = useMemo(
    () => elevation?.styles.findIndex(({ id }) => id === elevationStyle?.id) ?? 0,
    [elevation, elevationStyle],
  );

  // When there is no planId selected, usually when a customer is performing a
  // new configuration, we want to selected the first plan.
  useEffect(() => {
    if (!plan && plans) setPlan(plans[0]);
  }, [plan, plans, setPlan]);

  // Do not show the left pane if there are no plans or plan selected.
  if (!plans || !plan) return null;

  return (
    <div css={Css.df.fdc.gap3.$}>
      <SelectField
        label="Floor Plan"
        items={plans}
        selectedKey={plan.id}
        // TODO: We should have this return the selected plan object and not just the id.
        onSelectionChange={(planId) => setPlan(plans.find((plan) => plan.id === planId)!)}
      >
        {({ name, bedrooms, bathrooms, priceInCents }) => (
          <Item textValue={name}>
            <div css={Css.df.fdc.gap1.tl.$}>
              <p css={Css.xl2.$}>{name}</p>
              <div css={Css.df.gap2.sm.jcfs.aic.$}>
                <div css={Css.df.gap1.aic.jcc.$}>
                  <Icon name="bed" />
                  <p css={Css.sm.$}>{bedrooms}</p>
                </div>
                <div css={Css.df.gap1.aic.jcc.$}>
                  <Icon name="faucet" />
                  <p css={Css.sm.$}>{bathrooms}</p>
                </div>
                <p css={Css.$}>{currencyFormatter(priceInCents)}</p>
              </div>
            </div>
          </Item>
        )}
      </SelectField>

      <RadioField label="Exterior Style" options={plan?.elevations} value={elevation?.id} onChange={setElevation}>
        {(option, context) => {
          const { name, priceInCents, styles } = option;
          const { selected } = context;
          // Show the first image of the elevation style
          // Note that not all elevations have the same number of styles.
          // TODO: This is awkward since we end up showing a different style here.
          const safeStyleIndex = Math.min(elevationStyleIndex, styles.length - 1);
          const image = styles[safeStyleIndex].exteriorImages[0].image;
          return (
            <SelectionCard name={name} priceInCents={priceInCents} image={image ?? undefined} selected={selected} />
          );
        }}
      </RadioField>

      <RadioField
        label="Exterior Scheme"
        options={elevation?.styles}
        value={elevationStyle?.id}
        onChange={setElevationStyle}
      >
        {({ id, name, priceInCents, image }, { selected }) => {
          return (
            <SelectionCard
              key={id}
              size="sm"
              name={name}
              priceInCents={priceInCents}
              image={image ?? undefined}
              selected={selected}
            />
          );
        }}
      </RadioField>

      {/* Personalization information */}
      <div css={Css.br4.bgGray200.p2.df.fdc.gap1.$}>
        <div css={Css.df.gap1.$}>
          <div>
            <Icon name="flashlight" />
          </div>
          <p css={Css.gray900.sm.$}>More personalization available once you connect with the Homebound team.</p>
        </div>
      </div>
    </div>
  );
}

type RightPaneProps = {
  configurationValues: ArchitectureConfigurationValues;
};
function RightPane(props: RightPaneProps) {
  const { configurationValues } = props;
  const { plan, elevationStyle } = configurationValues;

  const { name, description, bedrooms, bathrooms, garageParking, sqft } = plan ?? {};

  if (!plan || !elevationStyle) return null;
  return (
    <div css={Css.df.fdc.w100.$}>
      {/* TODO: Add carousel to this */}
      <img css={Css.w100.objectCover.hPx(576).$} src={elevationStyle.exteriorImages[0].image ?? undefined} alt={name} />

      {/* Disclaimer */}
      <p css={Css.gray600.tiny.tr.mt1.mr1.$}>
        <em>
          Renderings are for representational purposes only. Layout, specifications, and finishes may vary or change.
        </em>
      </p>

      {/* Bottom Content */}
      <div css={Css.pxPx(8 * 10).pt4.$}>
        {/* Heading */}
        <h1 css={Css.xl3Em.mb3.$}>{name}</h1>

        {/* About and Stats */}
        <div css={Css.mb3.$}>
          <h2 css={Css.xlEm.mb1.$}>About</h2>
          {/* Row with the description and stats */}
          <div css={Css.df.fdr.jcsb.gap4.$}>
            {/* Description */}
            <div css={Css.maxwPx(400).$}>
              <p css={Css.sm.mb2.$}>
                {description}
                <sup>[1]</sup>
              </p>
              <p css={Css.xsEm.gray700.mb1.$}>
                [1] Architecture shown may not be an exact match to options selected but will be confirmed after
                reservation.
              </p>
            </div>
            {/* Stats */}
            <div css={Css.bl.bGray300.pl7.fg1.df.aifs.jcc.$}>
              <div css={Css.dg.gtc("1fr 1fr").gap4.$}>
                {/* Bedrooms */}
                <div css={Css.nowrap.df.fdr.aic.gap2.$}>
                  <Icon name="bed" />
                  <div css={Css.df.fdc.$}>
                    <p css={Css.tinyEm.ttu.gray700.$}>Bedrooms</p>
                    <p css={Css.xl2Em.$}>{bedrooms}</p>
                  </div>
                </div>
                {/* Square Feet */}
                <div css={Css.nowrap.df.fdr.aic.gap2.$}>
                  <Icon name="sqft" />
                  <div css={Css.df.fdc.$}>
                    <p css={Css.tinyEm.ttu.gray700.$}>Square Feet</p>
                    <p css={Css.xl2Em.$}>{sqft}</p>
                  </div>
                </div>
                {/* Bathrooms */}
                <div css={Css.nowrap.df.fdr.aic.gap2.$}>
                  <Icon name="faucet" />
                  <div css={Css.df.fdc.$}>
                    <p css={Css.tinyEm.ttu.gray700.$}>Bathrooms</p>
                    <p css={Css.xl2Em.$}>{bathrooms}</p>
                  </div>
                </div>
                {/* Parking Garage */}
                <div css={Css.nowrap.df.fdr.aic.gap2.$}>
                  <Icon name="square-parking" />
                  <div css={Css.df.fdc.$}>
                    <p css={Css.tinyEm.ttu.gray700.$}>Garage Parking</p>
                    <p css={Css.xl2Em.$}>{garageParking}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Floor Plans Section */}
        {/* Header with note */}
        <div css={Css.df.jcsb.$}>
          <h2 css={Css.xlEm.mb3.$}>Floor Plans</h2>
        </div>
        {/* Floor Plan */}
        <div css={Css.df.fdc.gap4.$}>
          {[...plan.floors].sort((a, b) => parseInt(a.name) - parseInt(b.name)).map(FloorPlanCard)}
        </div>
      </div>
      <NextCTA
        to="/interior"
        heading="Next: Choose Your Interior"
        subheading="Choose interior styles and finish levels."
      />
    </div>
  );
}
